import React, { useState, useEffect, useRef } from 'react';
import SideMenu from './side-menu';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { DataTable } from 'primereact/datatable';
import sound from "../playsound/notification.mp3"
import useSound from 'use-sound';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { OrderList } from 'primereact/orderlist';
import { ApiUrlService } from '../api-library/api-services';
import { RadioButton } from 'primereact/radiobutton';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css';
import { ColumnGroup } from 'primereact/columngroup';
import AsyncStorage from '@react-native-async-storage/async-storage';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { Row } from 'primereact/row';
const OrdersTable = () => {
    let emptyOrder = {
        order_id: '',
        customer_id: '',
        totalAmount: '',
        expectedMoneyRange: '',
        item: [],
        driverId: '',
        subZoneToId: '',
        toLocation_id: '',
        deliveryCharge: '',
        notes: [],
        status_id: ''

    };


    let componentRef = React.useRef();
    const [rowDataArray, setRowDataArray] = useState('');
    const [imageTitle, setImageTitle] = useState('');
    const [srcImage, setSrcImage] = useState('');
    const [orders, setOrders] = useState([]);
    const [orderAccepted, setOrderAccepted] = React.useState(true);
    const [orderDialog, setOrderDialog] = useState(false);
    const [deleteOrderDialog, setDeleteOrderDialog] = useState(false);
    const [deleteOrdersDialog, setDeleteOrdersDialog] = useState(false);
    const [order, setOrder] = useState(emptyOrder);
    const [selectedOrders, setSelectedOrders] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [imageDialog, setImageDialog] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    let today = new Date();
    const [divID, setDivID] = useState(1);
    const apiUrlService = new ApiUrlService()
    const [showCountDown, setCountDown] = React.useState(false);
    const [disabledButton, setDisabled] = React.useState(true);
    const [loading, setLoading] = useState(false);
    const [selectedPriority, setSelectedPriority] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [priorities, setPriorities] = useState([])
    const [statuses, setStatuses] = useState([])

    const [inputList, setInputList] = useState([{ image: {}, name: "" }]);
    const [productList, setProductList] = useState([{ image: {}, name: "", price: "", quantity: "" }]);
    const [disableSave, setDisableSave] = React.useState(false);
    const [date, setDate] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
      const [selectDate, setSelectDate] = useState(null);
      const [count,setCount] = useState(0);

   const [play] = useSound(sound);
    const countries = [
        { name: 'Notting Hill', code: '1cdb2596-2cc7-4881-93f7-d040e81791c5' },
        { name: 'Knightsbbridge', code: '5257935a-e440-47a2-8ef7-97e27d9195f2' }
    ];

    useEffect(() => {
         
        var dateObj = new Date();
 
        if(selectDate != null && selectedCountry == null){

        getOrders(selectDate)
    }

    if(selectDate == null && selectedCountry == null){

        var month = dateObj.getMonth()<9? '0'+(dateObj.getMonth()+ 1) : dateObj.getMonth()+1;
        var day = dateObj.getDate()<=9 ? '0'+(dateObj.getDate()) : dateObj.getDate();
        var year = dateObj.getUTCFullYear();
        
       var  newdate = year + "/" + month + "/" + day;
     
        setDate(new Date(newdate))
        getOrders(newdate)
    }

    if(selectDate != null && selectedCountry != null){
   
        getOrdersByBranch(selectDate,selectedCountry.code);
    }

     if(selectDate == null && selectedCountry != null){

            var month = dateObj.getMonth()<9? '0'+(dateObj.getMonth()+ 1) : dateObj.getMonth()+1;
        var day = dateObj.getDate()<=9 ? '0'+(dateObj.getDate()) : dateObj.getDate();
        var year = dateObj.getUTCFullYear();
        
       var  newdate = year + "/" + month + "/" + day;
     
        setDate(new Date(newdate))
        getOrdersByBranch(newdate,selectedCountry.branch);
    }

   
        
   setTimeout(function () {
    if(selectDate != null && selectedCountry == null){

        getOrders(selectDate)
    }

    if(selectDate == null && selectedCountry == null){

        var month = dateObj.getMonth()<9? '0'+(dateObj.getMonth()+ 1) : dateObj.getMonth()+1;
        var day = dateObj.getDate()<=9 ? '0'+(dateObj.getDate()) : dateObj.getDate();
        var year = dateObj.getUTCFullYear();
        
       var  newdate = year + "/" + month + "/" + day;
     
        setDate(new Date(newdate))
        getOrders(newdate)
    }

    if(selectDate != null && selectedCountry != null){
   
        getOrdersByBranch(selectDate,selectedCountry.code);
    }

     if(selectDate == null && selectedCountry != null){

            var month = dateObj.getMonth()<9? '0'+(dateObj.getMonth()+ 1) : dateObj.getMonth()+1;
        var day = dateObj.getDate()<=9 ? '0'+(dateObj.getDate()) : dateObj.getDate();
        var year = dateObj.getUTCFullYear();
        
       var  newdate = year + "/" + month + "/" + day;
     
        setDate(new Date(newdate))
        getOrdersByBranch(newdate,selectedCountry.branch);
    }
          
        }, 3000);



     
    }, []);

    
     const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex justify-content-center">
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="flex justify-content">
              
                <div>{option.name}</div>
            </div>
        );
    };


    const getOrders = async (date) => {
        setOrders([])
        setLoading(true)
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
        var service = {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + myUser.token
            }
        }

             fetch('https://api.wingo-api.com/api/orderInProgress?dateCreated=' + date + '', service).then(res => res.json())
            .then(response => {
                setOrders(response.data)
                 if(response.data.length > 0){
                     play();
                }
                setCount(response.data.length)
                setLoading(false)
            });
       
       
    }

      const getOrdersByBranch = async (date,branch) => {
        setOrders([])
        setLoading(true)
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
        var service = {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + myUser.token
            }
        }

             fetch('https://api.wingo-api.com/api/orderInProgressAndBranch?dateCreated=' + date + '&branch=' + branch + '', service).then(res => res.json())
            .then(response => {
                setOrders(response.data)
                setLoading(false)
                if(response.data.length > 0){
                     play();
                }
                setCount(response.data.length)
                console.log('https://api.wingo-api.com/api/orderInProgressAndBranch?dateCreated=' + date + '&branch=' + branch + '')
            });
      
       
    }

    const timerProps = {
        isPlaying: true,
        size: 40,
        strokeWidth: 6
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _order = { ...order };
        _order[`${name}`] = val;
        setOrder(_order);
    }



    const hideDeleteOrderDialog = () => {
        setDeleteOrderDialog(false);
    }

    const hideDeleteOrdersDialog = () => {
        setDeleteOrdersDialog(false);
    }
    const editOrder = async (order) => {
        statuses.length = 0
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))

        setOrder({ ...order });
        setInputList([{ image: {}, name: "" }])
        setProductList([{ image: {}, name: "", price: "", quantity: "" }])
        setLoading(true)
        if (order.cart.length === 0) {
            setInputList([])
            var service = apiUrlService.GetApi();
            fetch('https://api.wingo-api.com/api/order/' + order.order_id + '', service).then(res => res.json())
                .then(response => {
                    setProductList(response.data)

                })
        }
        else if (order.cart.length !== 0) {
            setProductList([])
            setInputList(order.cart)
            setLoading(false)
            setOrderDialog(true);
        }
        var service = {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + myUser.token
            }
        }
        fetch('https://api.wingo-api.com/api/orderStatus', service).then(res => res.json())
            .then(response => {
                statuses.length = 0
                for (var j = 0; j < response.data.length; j++) {
                    const data = {
                        label: response.data[j].name,
                        value: response.data[j].orderStatus_id
                    }
                    statuses.push(data)
                }
                setStatuses(statuses);
                setSelectedStatus(order.orderStatus.orderStatus_id)
            });

    }

    const confirmDeleteOrder = (order) => {
        setOrder(order);
        setDeleteOrderDialog(true);
    }

    const deleteOrder = async () => {
        setLoading(true)
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
        var service = {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + myUser.token
            }
        }
        fetch('https://api.wingo-api.com/api/order/' + order.order_id + '', service).then(res => res.json())
            .then(response => {
                if (response.status === true) {
                    setDeleteOrderDialog(false);
                    setOrder(emptyOrder);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Order Deleted', life: 3000 });
                    setOrders([])
                    searchClick()
                }
            })
    }
    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const dialogFuncMap = {
        'displayDialog': setDisplayDialog,
    }
    const deleteSelectedOrders = () => {
        let _orders = orders.filter(val => !selectedOrders.includes(val));
        setOrders(_orders);
        setDeleteOrdersDialog(false);
        setSelectedOrders(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Orders Deleted', life: 3000 });
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const onSelectBranch = (val) =>{

    
      setSelectedCountry(val)

         var dateObj = new Date();


    if(selectDate != null){
   
        getOrdersByBranch(selectDate,val.code);
    }

     if(selectDate == null){

            var month = dateObj.getMonth()<9? '0'+(dateObj.getMonth()+ 1) : dateObj.getMonth()+1;
        var day = dateObj.getDate()<=9 ? '0'+(dateObj.getDate()) : dateObj.getDate();
        var year = dateObj.getUTCFullYear();
        
       var  newdate = year + "/" + month + "/" + day;
     
        setDate(new Date(newdate))
        getOrdersByBranch(newdate,val.code);
    }

   

    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>

                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteOrder(rowData)} />
            </React.Fragment>
        );
    }



    const actionImageTemplate = (rowData) => {
        return (
            <React.Fragment>
                <img src={rowData.image} className="productImage" onClick={e => openImage(rowData.name, rowData.image)} />
            </React.Fragment>
        );
    }
    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const deleteOrderDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteOrderDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteOrder} />
        </React.Fragment>
    );
    const deleteOrdersDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteOrdersDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedOrders} />
        </React.Fragment>
    );
    const statusBodyTemplate = (rowData) => {
        var x = rowData.orderStatus.name.replace(/\s/g, '');
        return <span className={`product-badge status-${x.toLowerCase()}`}>{rowData.orderStatus.name}</span>;
    }

     const branchBodyTemplate = (rowData) => {
        if(rowData.branch === null){
            return <span>Knightsbbridge</span>;
        }
       
        if(rowData.branch === '1cdb2596-2cc7-4881-93f7-d040e81791c5'){
 return <span>Notting Hill</span>;
        }
        
        if(rowData.branch == '5257935a-e440-47a2-8ef7-97e27d9195f2'){
           return <span>Knightsbbridge</span>;  
        }
       
    }


    const actionEditTemplate = (rowData) => {
        return (
            <React.Fragment>

                <Button icon="pi pi-pencil" className="p-button-rounded p-button-primary p-mr-2" onClick={() => editOrder(rowData)} />

            </React.Fragment>
        );
    }
    const searchClick = () => {
       
        var selectedDate = date
      
        var month = selectedDate.getMonth()<9 ? '0'+(selectedDate.getMonth()+ 1) : selectedDate.getMonth()+1;
        var day = selectedDate.getDate()<=9 ? '0'+(selectedDate.getDate()) : selectedDate.getDate();
        var year = selectedDate.getUTCFullYear();
        
        selectedDate = year + "/" + month + "/" + day;
        console.log(selectedDate)
        setSelectDate(selectedDate);

         if(selectedCountry != null){
    
            getOrdersByBranch(selectedDate, selectedCountry.code);
        }else{
            getOrders(selectedDate)
        }
        
    }
    const refreshClick = () => {
        var selectedDate = date
      
        var month = selectedDate.getMonth()<9 ? '0'+(selectedDate.getMonth()+ 1) : selectedDate.getMonth()+1;
        var day = selectedDate.getDate()<=9 ? '0'+(selectedDate.getDate()) : selectedDate.getDate();
        var year = selectedDate.getUTCFullYear();
        
        selectedDate = year + "/" + month + "/" + day;
        getOrders(selectedDate)
    }

    const hideDialog = () => {
        setOrderDialog(false)
        setSubmitted(false)
    }
    const hideDialogImage = () => {
        setImageDialog(false)
    }

    const openImage = (name, src) => {
        setImageDialog(true)
        setSrcImage(src)
        setImageTitle(name)
    }
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };
    const handleAddClick = () => {
        setInputList([...inputList, { image: {}, name: "" }]);
    };
    const orderDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
        </React.Fragment>
    );
    const inProgressBodyTemplate = (rowData) => {
        return <div>{rowData.orderStatus.orderStatus_id === 3 ? <Checkbox inputId="binary" checked={true} /> : <Checkbox inputId="binary" checked={false} />}</div>

    }
    let headerGroup = <ColumnGroup>
        <Row>
            <Column header="" />
            <Column header="Order" colSpan={4} />
            <Column header="Customer" colSpan={2} />
            <Column header="" />
        </Row>
        <Row>
            <Column header="" />
            <Column header="Number" />
            <Column header="Status" />
            <Column header="Date" />
             <Column header="Branch" />
            <Column header="Name" />
            <Column header="Number" />
            <Column header="In Progress?" />
        </Row>
    </ColumnGroup>;

    const changeOrderStatus = async (val) => {
        const body =
        {
            "orderStatus": {
                "orderStatus_id": val
            }
        }
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
        setLoading(true)
        var service = {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + myUser.token
            }
        }
        fetch('https://api.wingo-api.com/api/updateStatus/' + order.order_id + '', service).then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Status Updated Successfully', life: 3000 });

                    setSelectedStatus(val)
                    setLoading(false)
                    setOrderDialog(false)
                    refreshClick();


                }
            })
    }
    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 pl-0">
                            <div className="page-title-box">
                                <h4 className="page-title">Orders</h4>
                            </div>
                        </div>
                    </div>
                     <div className="cardd flex justify-content-center">
            <Dropdown value={selectedCountry} onChange={(e) => onSelectBranch(e.value)} options={countries} optionLabel="name" placeholder="Select a Branch" 
                filter valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} />
        </div>  
                    <div className="row p-grid pb-2 pt-2">
                        <div className="col-12">Select a Date</div>
                        <div className="col-12 col-sm-10 col-md-12 col-lg-12 d-flex">
                            <Calendar id="range" className="pr-4" value={date} onChange={(e) => setDate(e.value)} readOnlyInput dateFormat="yy/mm/dd" />
                            <Button label=" Search" icon="pi pi-search " className="p-button-warning mr-1" onClick={searchClick} />
                            <Button label=" Refresh" icon="pi pi-refresh " className="p-button-info" onClick={refreshClick} />
                        </div>
                    </div>
                    <div className="pt-3">
                        <Toast ref={toast} />
                        <div className="datatable-responsive-demo">
                            <div className="card ">
                                <Toolbar className="p-mb-4" right={rightToolbarTemplate} left={header} ></Toolbar>
                                <DataTable ref={dt} value={orders} showGridlines
                                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} headerColumnGroup={headerGroup}
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" scrollable scrollHeight="300px" rows={20} loading={loading}
                                    globalFilter={globalFilter} scrollable scrollHeight="280px">
                                    <Column body={actionEditTemplate} ></Column>
                                    <Column field="orderNumber" sortable></Column>
                                    <Column field="orderStatus" sortable body={statusBodyTemplate}></Column>
                                    <Column field="dateCreated" sortable></Column>
                                    <Column field="branch" body={branchBodyTemplate} sortable></Column>
                                    <Column field="customer.fullName" sortable></Column>
                                    <Column field="customer.phoneNumber" sortable></Column>
                                    <Column field="needDriver" body={inProgressBodyTemplate} sortable></Column>
                                    <Column body={actionBodyTemplate}></Column>
                                </DataTable>
                            </div>
                        </div>

                        <Dialog visible={deleteOrderDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteOrderDialogFooter} onHide={hideDeleteOrderDialog}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                {order && <span>Are you sure you want to delete order <b>{order.id}</b>?</span>}
                            </div>
                        </Dialog>



                        <Dialog visible={orderDialog} style={{ width: '1000px' }} header="Order Details" modal className="p-fluid" footer={orderDialogFooter} onHide={hideDialog}>
                            <div className="p-field">
                                <label htmlFor="status">Status</label>
                                <Dropdown id="status" options={statuses} value={selectedStatus} onChange={(e) => changeOrderStatus(e.value)} placeholder="Status" />
                            </div>
                            <div className="p-field">

                                <label htmlFor="phoneNumber">Total Amount</label>
                                <InputText value={order.totalAmount} onChange={(e) => onInputChange(e, 'totalAmount')} />
                            </div>
                                 {order.promoCode && <label htmlFor="phoneNumber">PromoCode</label>}
                                {order.promoCode ? <InputText value={order.promoCode.code}/>: <label htmlFor="phoneNumber"></label>}
                                
                                <div>
                                     {order.coupon && <label htmlFor="phoneNumber">Coupon</label>}
                                {order.coupon ? <InputText value={order.coupon.description}/>: <label htmlFor="phoneNumber"></label>}
                                    </div>
                            {inputList.length > 0 &&
                                <div className="p-field" ref={el => (componentRef = el)}>
                                    <label htmlFor="phoneNumber">Items</label>
                                    {inputList.map((x, i) => {
                                        return (
                                            <div className="mr-1 mb-1 d-flex " style={{ width: '700px' }}>
                                                <InputTextarea
                                                    name="name"
                                                    placeholder="Order dscripion"
                                                    value={x && x.product && x.product.name}
                                                    onChange={e => handleInputChange(e, i)} rows={1} cols={1} />
                                                <label style={{ width: '250px', paddingLeft: 20 }}> Quantity: {x && x.quantity}</label>
                                                  {x&&x.notes? <label style={{ width: '500px',paddingLeft:30  }}>Notes: {x&&x.notes}</label>:<label></label>}
                                                     {x&& x.itemsAddOns && x.itemsAddOns.map(z =>{
                                                        return (<label></label>)
                                                    })}
                                                {x && x.product && x.product.image !== null ? x.product.image && <img style={{ width: '100px', cursor: 'pointer', paddingLeft: 40 }} src={x.product.image} onClick={e => openImage(x.name, x.product.image)} /> : <label htmlFor="phoneNumber"></label>}


                                            </div>
                                        );
                                    })}
                                </div>
                            }
                            {productList.length > 0 &&
                                <div className="p-field">
                                    <label htmlFor="phoneNumber">Products</label>
                                    <div className="card">
                                        <DataTable value={productList} responsiveLayout="scroll">
                                            <Column field="name" header="Name"></Column>
                                            <Column field="price" header="Price"></Column>
                                            <Column field="image" header="Image" body={actionImageTemplate}></Column>
                                            <Column field="quantity" header="Quantity"></Column>
                                            <Column field="notes" header="Note"></Column>
                                        </DataTable>
                                    </div>
                                </div>
                            }
                            {order.notes && <div className="p-field">
                                <label htmlFor="phoneNumber">Notes</label>
                                {order.notes.map((x) => {
                                    return (
                                        <div className="mr-1 mb-1 d-flex ">
                                            <p>- {x}</p>
                                        </div>
                                    );
                                })}
                            </div>}
                            <ReactToPrint
                                trigger={() => {
                                    return <Button className="print">Print</Button>
                                }}
                                content={() => componentRef}
                                documentTitle='new doc'
                                pageStyle="print"
                            />
                        </Dialog>

                        <Dialog visible={imageDialog} style={{ width: '450px' }} header={imageTitle} modal className="p-fluid" onHide={hideDialogImage}>
                            <div className="datatable-responsive-demo">
                                <div className="card ">
                                    <img style={{ width: '400px' }} src={srcImage} />
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div >
        </div >
    )
}
function PendingOrders() {
    return (
        <>
            <div className="App">
                <SideMenu />
                <OrdersTable />
            </div>
        </>
    );
}
export default PendingOrders;
