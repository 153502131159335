
const login = (email, password) => {
    const data = new FormData();
    data.append("email", email);
    data.append("password", password);
   return fetch("https://api.wingo-api.com/api/login?email="+email+"&password="+password, {
    method: 'POST',
   body: {},
  }).then(res => res.json())
   .then((response) => {
      if (response.access_token) {
       localStorage.setItem("user", JSON.stringify(response.access_token));
      }
   return response;
   });
};

const logout = () => {
  localStorage.clear()
};



export default {
  login,
  logout
};
