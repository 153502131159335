import React, { useState, useEffect, useRef } from 'react';
import SideMenu from './side-menu';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { format } from 'date-fns';
import { Checkbox } from 'primereact/checkbox';
import { ApiUrlService } from '../api-library/api-services';
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css';
import AsyncStorage from '@react-native-async-storage/async-storage';

const CouponsList = () => {

    let emptyCoupon = {
        "title":'',
        "percentage":0,
        "price":null,
        "description":null,
        "type":null,
        "toDate":"",
        "category":null,
        "product":null
    };
    const apiUrlService = new ApiUrlService()
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(false);
    const [coupons, setCoupons] = useState([]);
    const [couponDialog, setcouponDialog] = useState(false);
    const [couponDialog2, setcouponDialog2] = useState(false);
    const [restrictionDialog, setRestrictionDialog] = useState(false);
    const [deletecouponDialog, setDeletecouponDialog] = useState(false);
    const [deletecouponsDialog, setDeletecouponsDialog] = useState(false);
    const [coupon, setCoupon] = useState(emptyCoupon);
    const [expirationDate, setExpDate] = useState(null);
    const [newDate, setExpirationDate] = useState(null);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [categories, setCategories]= useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [type, setType]=  useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [restrictions, setRestriction]= useState([]);
    const [rest,setRest]= useState('');
 


    const toast = useRef(null);
    const dt = useRef(null);

    const [submitted, setSubmitted] = useState(false);

    const addRestriction = async (coupon) =>{
        setCoupon(coupon);
        setRestrictionDialog(true);
    }

    const getRestriction = async (coupon) => {
         const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
         var service = {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + myUser.token
            }
        }
        fetch('https://api.wingo-api.com/api/couponRestriction?coupon='+coupon, service).then(res => res.json())
            .then(data =>
                setRestriction(data.data))
                setLoading(false);
    }

    const saveRestriction = async() =>{
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
         const body =
            {
                 "title":rest,
                "coupon": {coupon_id: coupon.coupon_id}

            }
            var service = apiUrlService.PostApi(body,myUser.token)
            fetch('https://api.wingo-api.com/api/couponRestriction', service).then((response) => response.json())
                .then((response) => {
                    setLoading(false)
                    if (response.status === true) {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Saved', life: 3000 });
                        getCoupon()
                        setRestrictionDialog(false);
                        setCoupon(emptyCoupon);
                        setRest('');
                    }
                })
    }


    const getCoupon = async () => {
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
        var service = {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + myUser.token
            }
        }
        fetch('https://api.wingo-api.com/api/coupon?customer=f5e8a04f-d3b7-48cc-a55e-f89e0fa4fb26', service).then(res => res.json())
            .then(data =>
                setCoupons(data.data))
                setLoading(false);
    }
    useEffect(() => {
        getCoupon()

           const types = [{
           label: "Discount",
            value: "Discount"
    },
    {
           label: "Cashback",
            value: "Cashback"
    }
]

setType(types)


        var service = apiUrlService.GetApi();
        fetch('https://api.wingo-api.com/api/products', service).then(res => res.json())
            .then(response => {
                products.length = 0
                for (var j = 0; j < response.data.length; j++) {
                    const dataa = {
                        label: response.data[j].name,
                        value: response.data[j].product_id
                    }
                    products.push(dataa)
                }
                //alert(JSON.stringify(products))
                setProducts(products);
            });

          fetch('https://api.wingo-api.com/api/category', service).then(res => res.json())
            .then(response => {
                products.length = 0
                for (var j = 0; j < response.data.length; j++) {
                    const data = {
                        label: response.data[j].title,
                        value: response.data[j].category_id
                    }
                    categories.push(data)
                }
                setCategories(categories);
            });

    }, []);

    const editCoupon = async (order) => {

        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'));
        setCoupon(order);
            var date = new Date(order.toDate); 
     
        setExpirationDate(new Date(date))
        getRestriction(order.coupon_id)
        setcouponDialog2(true);
        

       

    }

    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const exportCSV = () => {
        dt.current.exportCSV();
    }
    const openNew = () => {
        setCoupon(emptyCoupon);
        setSubmitted(false);
        setcouponDialog(true);
    }
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _coupon = { ...coupon };
        _coupon[`${name}`] = val;

        setCoupon(_coupon);
    }
    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _coupon = {...coupon};
        _coupon[`${name}`] = val;

        setCoupon(_coupon);
    }
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

     const actionEditTemplate = (rowData) => {
        return (
            <React.Fragment>

                <Button icon="pi pi-pencil" className="p-button-rounded p-button-primary p-mr-2" onClick={() => editCoupon(rowData)} />
                <Button  icon="pi pi-trash" style={{marginLeft:10}} className="p-button-rounded p-button-danger p-mr-2" onClick={() => showDeleteDialog(rowData)}  />
                <Button label="Add Restriction" icon="pi pi-plus"  style={{marginLeft:-25, width:170}}  className="p-button-text p-mr-2" onClick={()=>addRestriction(rowData)} />

            </React.Fragment>
        );
    }

    const showDeleteDialog = (coup) => {
        setCoupon(coup)
        setDeletecouponDialog(true)
    }

     const hideDeleteDialog = () => {
        setCoupon(emptyCoupon)
        setDeletecouponDialog(false)
    }

    

      const deletecoupon = async() => {
        setLoading(true)
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))

        if (coupon.coupon_id) {
            var service = apiUrlService.DeleteApi(myUser.token);
            fetch('https://api.wingo-api.com/api/coupon/' + coupon.coupon_id + '', service).then(res => res.json())
                .then(response => {
                    setLoading(false)
                    if (response.status === true) {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'coupon Deleted Succesfully', life: 3000 });
                        getCoupon()
                        setDeletecouponDialog(false);
                        setCoupon(emptyCoupon);
                    }
                })
        }
        else {
      
        }

    }

     const deleteCategoryDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deletecoupon}/>
        </React.Fragment>
    );

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }
    const savecoupon = async() => {
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
  

        setSubmitted(true);
        if (coupon.title && expirationDate) {
            //alert(expirationDate)
            const body =
            {
                 "title":coupon.title,
                "percentage":coupon.percentage,
                "description":coupon.description,
                "type": selectedType,
                "toDate":format(expirationDate, 'yyyy-MM-dd kk:mm:ss'),
                "price": coupon.price,
                "product": selectedProduct == null ? null : {product_id: selectedProduct},
                "category": selectedCategory == null ? null : {category_id: selectedCategory}

            }
            var service = apiUrlService.PostApi(body,myUser.token)
            fetch('https://api.wingo-api.com/api/coupon', service).then((response) => response.json())
                .then((response) => {
                    setLoading(false)
                    if (response.status === true) {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'coupon Created', life: 3000 });
                        getCoupon()
                        setcouponDialog(false);
                        setCoupon(emptyCoupon);
                    }
                })
          
        }
        else{
            //alert(expirationDate)
        }
    }

    const editcoupon = async() => {
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))

        setSubmitted(true);
        if (coupon.title && newDate && coupon.percentage) {
       
            const body =
            {
                 "title":coupon.title,
                "percentage":coupon.percentage,
                "description":coupon.description,
                "type": selectedType,
                "toDate":format(expirationDate, 'yyyy-MM-dd kk:mm:ss'),
                "price": coupon.price,
                "product": selectedProduct == null ? null : {product_id: selectedProduct},
                "category": selectedCategory == null ? null : {category_id: selectedCategory}
            }
            var service = apiUrlService.PutApi(body,myUser.token)
            fetch('https://api.wingo-api.com/api/coupon/' + coupon.coupon_id, service).then((response) => response.json())
                .then((response) => {
                    setLoading(false)
                    if (response.status === true) {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'coupon Saved', life: 3000 });
                        getCoupon()
                        setcouponDialog2(false);
                        setCoupon(emptyCoupon);
                    }
                })
          
        }
    }
    const pressCashback=(e)=>{
        let _coupon = {...coupon};
        _coupon['cashback'] = e.checked;
        setCoupon(_coupon);
    }
    const cachBackBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'true-icon pi-check-circle': rowData.cashback, 'false-icon pi-times-circle': !rowData.cashback })}></i>;
    }
    const cachBackFilterTemplate = (options) => {
        return <TriStateCheckbox value={options.value} onChange={(e) => options.filterCallback(e.value)} />
    }
    const hideDialog = () => {
        setSubmitted(false);
        setcouponDialog(false);
    }

    const hideDialog2 = () => {
        setcouponDialog2(false);
    }
    const couponDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={savecoupon} />
        </React.Fragment>
    );

    const couponDialogFooter2 = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog2} />
            <Button label="Edit" icon="pi pi-check" className="p-button-text" onClick={editcoupon} />
        </React.Fragment>
    );

     const restrictionFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={()=> setRestrictionDialog(false)} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveRestriction} />
        </React.Fragment>
    );

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 pl-0">
                            <div className="page-title-box">
                                <h4 className="page-title">Coupons</h4>
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                    <div className="pt-3" >
                        <Toast ref={toast} />
                        <div className="datatable-responsive-demo">
                            <div className="card ">
                                <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                                <DataTable ref={dt} value={coupons}
                                    dataKey="softDrink_id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} coupons"
                                    globalFilter={globalFilter} header={header} responsiveLayout="scroll">
                                        <Column body={actionEditTemplate} ></Column>
                                    <Column field="title" header="Title" sortable></Column>
                                    <Column field="type" header="Type" sortable ></Column>
                                    <Column field="percentage" header="Percentage (%)" sortable ></Column>
                                    <Column field="description" header="Description" sortable/>
                                    <Column field="price" header="Points" sortable ></Column>
                                    <Column field="toDate" header="Expiration Date" sortable ></Column>
                                </DataTable>
                            </div>
                        </div>

                    </div>
                    <Dialog visible={couponDialog} style={{ width: '600px' }} header="Coupon Details" modal className="p-fluid" footer={couponDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="name">Title</label>
                            <InputText id="name" value={coupon.title} onChange={(e) => onInputChange(e, 'title')} required autoFocus className={classNames({ 'p-invalid': submitted && !coupon.title })} />
                            {submitted && !coupon.title && <small className="p-error">title is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">Description</label>
                            <InputText id="name" value={coupon.description} onChange={(e) => onInputChange(e, 'description')} required className={classNames({ 'p-invalid': submitted && !coupon.description })} />
                            {submitted && !coupon.description && <small className="p-error">description is required.</small>}
                        </div>
                         <div className="field">
                            <label htmlFor="name">Type</label>
                             <Dropdown filter showClear options={type} value={selectedType} onChange={(e) => setSelectedType(e.value)} placeholder="Type" />
                            {submitted && !selectedType && <small className="p-error">Type required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">Percentage</label>
                            <InputNumber value={coupon.percentage} onValueChange={(e) => onInputNumberChange(e, 'percentage')}  prefix="%"   min={0} max={100} mode="decimal" useGrouping={false} />
                            
                        </div>
                        <div className="field">
                            <label htmlFor="name">Expiration Date</label>
                            <Calendar id="basic" value={expirationDate} showTime showSeconds onChange={(e) => setExpDate(e.value)} className={classNames({ 'p-invalid': submitted && !expirationDate })} />
                            {submitted && !expirationDate && <small className="p-error">Expiration Date is required.</small>}
                        </div>
                         <div className="field">
                            <label htmlFor="name">Points</label>
                            <InputNumber value={coupon.price} onValueChange={(e) => onInputNumberChange(e, 'price')} required  min={0} max={100} mode="decimal" useGrouping={false} className={classNames({ 'p-invalid': submitted && !coupon.price })} />
                            {submitted && !coupon.price && <small className="p-error">price is required.</small>}
                        </div>
                         <div className="field">
                            <label htmlFor="name">Link to Product</label>
                            <Dropdown filter showClear options={products} value={selectedProduct} onChange={(e) => setSelectedProduct(e.value)} placeholder="Product" />
                           
                        </div>
                        <div className="field">
                            <label htmlFor="name">Link to Category</label>
                             <Dropdown filter showClear options={categories} value={selectedCategory} onChange={(e) => setSelectedCategory(e.value)} placeholder="Category" />
                          
                        </div>
                        
                    </Dialog>



             <Dialog visible={deletecouponDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCategoryDialogFooter} onHide={() => setDeletecouponDialog(false)}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                {coupon && <span>Are you sure you want to delete <b>' {coupon.description} '</b>?</span>}
                            </div>
                        </Dialog>

                <Dialog visible={couponDialog2} style={{ width: '600px' }} header="Coupon Details" modal className="p-fluid" footer={couponDialogFooter2} onHide={hideDialog2}>
                        <div className="field">
                            <label htmlFor="name">Title</label>
                            <InputText id="name" value={coupon.title} onChange={(e) => onInputChange(e, 'title')} required autoFocus className={classNames({ 'p-invalid': submitted && !coupon.title })} />
                            {submitted && !coupon.title && <small className="p-error">Title is required.</small>}
                        </div>
                          <div className="field">
                            <label htmlFor="name">Description</label>
                            <InputText id="name" value={coupon.description} onChange={(e) => onInputChange(e, 'description')} required autoFocus className={classNames({ 'p-invalid': submitted && !coupon.description })} />
                            {submitted && !coupon.description && <small className="p-error">description is required.</small>}
                        </div>
                         <div className="field">
                            <label htmlFor="name">Type</label>
                             <Dropdown filter showClear options={type} value={selectedType} onChange={(e) => setSelectedType(e.value)} placeholder={coupon.type} />
                           
                        </div>
                        <div className="field">
                            <label htmlFor="name">Percentage</label>
                            <InputNumber value={coupon.percentage} onValueChange={(e) => onInputNumberChange(e, 'percentage')} required prefix="%"   min={0} max={100} mode="decimal" useGrouping={false}/>
                            {submitted && !coupon.percentage && <small className="p-error">Percentage is required.</small>}
                        </div>
                         <div className="field">
                            <label htmlFor="name">Points</label>
                            <InputNumber value={coupon.price} onValueChange={(e) => onInputNumberChange(e, 'price')} required  min={0} max={100} mode="decimal" useGrouping={false} className={classNames({ 'p-invalid': submitted && !coupon.price })} />
                            {submitted && !coupon.price && <small className="p-error">price is required.</small>}
                        </div>
                          <div className="field">
                            <label htmlFor="name">Expiration Date</label>
                            <Calendar id="basic" value={expirationDate} showTime showSeconds onChange={(e) => setExpDate(e.value)} className={classNames({ 'p-invalid': submitted && !expirationDate })} />
                            {submitted && !expirationDate && <small className="p-error">Expiration Date is required.</small>}
                        </div>
                         <div className="field">
                            <label htmlFor="name">Link to Product</label>
                            <Dropdown filter showClear options={products} value={selectedProduct} onChange={(e) => setSelectedProduct(e.value)} placeholder={coupon.product && coupon.product.name} />
                          
                        </div>
                        <div className="field">
                            <label htmlFor="name">Link to Category</label>
                             <Dropdown filter showClear options={categories} value={selectedCategory} onChange={(e) => setSelectedCategory(e.value)} placeholder={coupon.category && coupon.category.title} />
                           
                        </div>

                        {restrictions.length > 0 &&  <div className="field">
                            <label htmlFor="name">Restrictions</label>
                            {restrictions.map(x=> 
                               { return (  <div><label> - {x.title}</label></div>)})}
                        </div>
                            }    
                         
                        
                    </Dialog>

                    <Dialog visible={restrictionDialog} style={{ width: '600px' }} header="Add Restrictions" modal className="p-fluid" footer={restrictionFooter} onHide={()=> setRestrictionDialog(false)}>
                        <div className="field">
                            <label htmlFor="name">Restriction</label>
                            <InputText id="name" value={rest} onChange={(e) => setRest(e.target.value)}  required autoFocus className={classNames({ 'p-invalid': submitted && !rest })} />
                            {submitted && !rest && <small className="p-error">Title is required.</small>}
                        </div>

                        </Dialog>

                </div>
            </div >
        </div >
    )
}
function Coupons() {
    return (
        <>
            <div className="App">
                <SideMenu />
                <CouponsList />
            </div>
        </>
    );
}
export default Coupons;
