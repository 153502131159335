import React, { useState, useEffect, useRef } from 'react';
import SideMenu from './side-menu';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ApiUrlService } from '../api-library/api-services';
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from 'primereact/dropdown';

const LabelseList = () => {

    let emptyLabels = {
        timeFrame: '',
        email: '',
        phoneNumber: '',
        labels_id:''
    };
    const apiUrlService = new ApiUrlService()
    const [labelsDialog, setlabelsDialog] = useState(false);
    const [labels, setlabels] = useState(emptyLabels);
    const [checkedBusy, setCheckedBusy] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [selectedLabel, setSelectedLabel] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);

    const onChangeCustomer = (e) => {
        setSelectedLabel(customers.find(x=> x.value == e.value).label)
        setSelectedValue(e.value);

    }

    const toast = useRef(null);

    const [submitted, setSubmitted] = useState(false);

     const pressBusy = (e) => {
    setCheckedBusy(e.checked);
  };

    const getLabels = () => {
        setlabels([])
        var service = apiUrlService.GetApi();

        fetch('https://api.wingo-api.com/api/labels', service).then(res => res.json())
            .then(data => {
                setlabels(data.data)
                setCheckedBusy(data.data.status);
            })
    }
    useEffect(() => {
        getLabels()

        const data = [{
            label: "Public Holiday or event",
            value: "We are sorry to say that we had to close our restaurant today due to public holiday/event that is occuring in our area.",
        },
        {
            label: "Refurbishment",
            value: "We are sorry to say that we had to close our restaurant today due to refurbishment for our restaurant, hope to see you soon again.",
        },
        {
            label: "Technical Issues",
            value: "We are facing some technical issues at our restaurant today and we had to close. Sorry for the inconvenience, hope to see you again.",
        }
    ]
        setCustomers(data)

    }, []);
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _labels = { ...labels };
        _labels[`${name}`] = val;

        setlabels(_labels);
    }
    const saveLabels = async() => {
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))

        setSubmitted(true);
        if (labels.phoneNumber.trim() && labels.email.trim() && labels.timeFrame.trim()) {
            const body = {

                "timeFrame": labels.timeFrame,
                "phoneNumber": labels.phoneNumber,
                "email": labels.email,
                "address": {
                    "x": "1234",
                    "z": "4321"
                },
                "title": selectedLabel != null ? selectedLabel : labels.title,
                "cause":selectedValue != null ? selectedValue : labels.cause,
                "status":checkedBusy,
                "aboutUs":labels.aboutUs,
                "noteTitle":labels.noteTitle,
                "noteDesc": labels.noteDesc,
                "location": labels.location
            }
            var service = apiUrlService.PutApi(body,myUser.token)
            fetch('https://api.wingo-api.com/api/labels/'+labels.labels_id, service).then((response) => response.json())
                .then((response) => {
                    if (response.status === true) {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Label Updated  Successfully', life: 3000 });
                        setSubmitted(false)
                        getLabels()
                        setlabelsDialog(false);
                        setlabels(emptyLabels);
                    }
                })
        }
    }
    const hideDialog = () => {
        setSubmitted(false);
        setlabelsDialog(false);
    }
    const labelsDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveLabels} />
        </React.Fragment>
    );
    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 pl-0">
                            <div className="page-title-box">
                                <h4 className="page-title">Labels</h4>
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                    <div className="pt-3" >
                        <Toast ref={toast} />
                        <p><b>* Informations</b></p>
                        <p><b>Phone Number: </b> {labels.phoneNumber}</p>
                        <p><b>Email:</b>  {labels.email}</p>
                        <p><b>Time Frame:</b> {labels.timeFrame}</p>
                        <p><b>Location:</b> {labels.location}</p>
                         <p><b>----------------------------------</b></p>
                        <p><b>* Cart</b></p>
                        <p><b>Note Title:</b> {labels.noteTitle}</p>
                        <p><b>Note Description:</b> {labels.noteDesc}</p>
                        <p><b>----------------------------------</b></p>
                        <p><b>* Status</b></p>
                        <div className="field-checkboxx">
                    <Checkbox
                      inputId="binary"
                      checked={checkedBusy}
                      //onChange={(e) => pressBusy(e)}
                    />
                    <label htmlFor="binary" className="labelcss">
                      <b>Accept Order</b>
                    </label>
                  </div>
                        <p><b>Cause Title:</b> {checkedBusy ? "---" : labels.title}</p>
                        <p><b>Cause Description:</b> {checkedBusy ? "---": labels.cause}</p>
                    </div>
                    <div>
                        <Button label="Edit" icon="pi pi-pencil" className="p-button" onClick={() => setlabelsDialog(true)} />

                    </div>
                    <Dialog visible={labelsDialog} style={{ width: '450px' }} header="Label Details" modal className="p-fluid" footer={labelsDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="name">Phonenumber</label>
                            <InputText id="name" value={labels.phoneNumber} onChange={(e) => onInputChange(e, 'phoneNumber')} required autoFocus className={classNames({ 'p-invalid': submitted && !labels.phoneNumber })} />
                            {submitted && !labels.phoneNumber && <small className="p-error">Phonenumber is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">Email</label>
                            <InputText id="name" value={labels.email} onChange={(e) => onInputChange(e, 'email')} required autoFocus className={classNames({ 'p-invalid': submitted && !labels.email })} />
                            {submitted && !labels.email && <small className="p-error">Email is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">Time Frame</label>
                            <InputText value={labels.timeFrame} onChange={(e) => onInputChange(e, 'timeFrame')} required autoFocus className={classNames({ 'p-invalid': submitted && !labels.timeFrame })} />
                            {submitted && !labels.timeFrame && <small className="p-error">Time Frame is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">Location</label>
                            <InputText value={labels.location} onChange={(e) => onInputChange(e, 'location')} required autoFocus className={classNames({ 'p-invalid': submitted && !labels.location })} />
                            {submitted && !labels.location && <small className="p-error">location Frame is required.</small>}
                        </div>
                         <div className="field">
                            <label htmlFor="name">Note Title</label>
                            <InputText value={labels.noteTitle} onChange={(e) => onInputChange(e, 'noteTitle')} required autoFocus className={classNames({ 'p-invalid': submitted && !labels.noteTitle })} />
                            {submitted && !labels.noteTitle && <small className="p-error">noteTitle is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">Note Description</label>
                            <InputText value={labels.noteDesc} onChange={(e) => onInputChange(e, 'noteDesc')} required autoFocus className={classNames({ 'p-invalid': submitted && !labels.noteDesc })} />
                            {submitted && !labels.noteDesc && <small className="p-error">noteDesc is required.</small>}
                        </div>
                    <div className="field-checkboxx" style={{marginTop:10}}>
                    <Checkbox
                      inputId="binary"
                      checked={checkedBusy}
                      onChange={(e) => pressBusy(e)}
                    />
                    <label htmlFor="binary" className="labelcss">
                      <b>Accept Order</b>
                    </label>
                  </div>
                         <div className="field" style={{marginTop:10}}>
                            <label htmlFor="name">Cause Title</label>
                             <div className="p-field">
                                    <Dropdown filter showClear options={customers} value={selectedValue} onChange={(e) => onChangeCustomer(e)} placeholder={labels.title} />
                                </div>
                      
                        </div>
                     
                    </Dialog>
                </div>
            </div >
        </div >
    )
}
function Labels() {
    return (
        <>
            <div className="App">
                <SideMenu />
                <LabelseList />
            </div>
        </>
    );
}
export default Labels;