import React, { useState, useEffect, useRef } from 'react';
import SideMenu from './side-menu';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { format } from 'date-fns';
import { Checkbox } from 'primereact/checkbox';
import { ApiUrlService } from '../api-library/api-services';
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css';
import AsyncStorage from '@react-native-async-storage/async-storage';

const PromoCodeList = () => {

    let emptyPromoCode = {
        "code":'',
        "percentage":0,
        "usage":null,
        "cashback":null,
        "expirationDate":""
    };
    const apiUrlService = new ApiUrlService()
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(false);
    const [promocodes, setPromoCodes] = useState([]);
    const [promoCodeDialog, setPromoCodeDialog] = useState(false);
    const [promoCodeDialog2, setPromoCodeDialog2] = useState(false);
    const [deletePromoCodeDialog, setDeletePromoCodeDialog] = useState(false);
    const [deletePromoCodesDialog, setDeletePromoCodesDialog] = useState(false);
    const [promoCode, setPromoCode] = useState(emptyPromoCode);
    const [expirationDate, setExpDate] = useState(null);
    const [newDate, setExpirationDate] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);

    const [submitted, setSubmitted] = useState(false);
    const getPromoCode = async () => {
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
        var service = {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + myUser.token
            }
        }
        fetch('https://api.wingo-api.com/api/promoCode', service).then(res => res.json())
            .then(data =>
                setPromoCodes(data.data))
                setLoading(false);
    }
    useEffect(() => {
        getPromoCode()

    }, []);

    const editOrder = async (order) => {

        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'));
        setPromoCode(order);
            var date = new Date(order.expirationDate); 
     
        setExpirationDate(new Date(date))
        setPromoCodeDialog2(true);
        

       

    }

    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const exportCSV = () => {
        dt.current.exportCSV();
    }
    const openNew = () => {
        setPromoCode(emptyPromoCode);
        setSubmitted(false);
        setPromoCodeDialog(true);
    }
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _promoCode = { ...promoCode };
        _promoCode[`${name}`] = val;

        setPromoCode(_promoCode);
    }
    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _promoCode = {...promoCode};
        _promoCode[`${name}`] = val;

        setPromoCode(_promoCode);
    }
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

     const actionEditTemplate = (rowData) => {
        return (
            <React.Fragment>

                <Button icon="pi pi-pencil" className="p-button-rounded p-button-primary p-mr-2" onClick={() => editOrder(rowData)} />
                <Button  icon="pi pi-trash" style={{marginLeft:10}} className="p-button-rounded p-button-danger p-mr-2" onClick={() => showDeleteDialog(rowData)}  />

            </React.Fragment>
        );
    }

    const showDeleteDialog = (promo) => {
        setPromoCode(promo)
        setDeletePromoCodeDialog(true)
    }

     const hideDeleteDialog = () => {
        setPromoCode(emptyPromoCode)
        setDeletePromoCodeDialog(false)
    }

    

      const deletePromoCode = async() => {
        setLoading(true)
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))

        if (promoCode.promoCodeId) {
            var service = apiUrlService.DeleteApi(myUser.token);
            fetch('https://api.wingo-api.com/api/promoCode/' + promoCode.promoCodeId + '', service).then(res => res.json())
                .then(response => {
                    setLoading(false)
                    if (response.status === true) {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'PromoCode Deleted Succesfully', life: 3000 });
                        getPromoCode()
                        setDeletePromoCodeDialog(false);
                        setPromoCode(emptyPromoCode);
                    }
                })
        }
        else {
      
        }

    }

     const deleteCategoryDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deletePromoCode}/>
        </React.Fragment>
    );

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }
    const savePromoCode = async() => {
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
   

        setSubmitted(true);
        if (promoCode.code.trim() && expirationDate && promoCode.percentage) {
           
            const body =
            {
                "code":promoCode.code,
                "percentage":promoCode.percentage,
                "usage":promoCode.usage,
                "cashback":promoCode.cashback,
                "expirationDate":format(expirationDate, 'yyyy-MM-dd kk:mm:ss')
            }
            var service = apiUrlService.PostApi(body,myUser.token)
            fetch('https://api.wingo-api.com/api/promoCode', service).then((response) => response.json())
                .then((response) => {
                    setLoading(false)
                    if (response.status === true) {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'PromoCode Created', life: 3000 });
                        getPromoCode()
                        setPromoCodeDialog(false);
                        setPromoCode(emptyPromoCode);
                    }
                })
          
        }
    }

    const editPromoCode = async() => {
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))

        setSubmitted(true);
        if (promoCode.code.trim() && newDate && promoCode.percentage) {
       
            const body =
            {
                "code":promoCode.code,
                "percentage":promoCode.percentage,
                "usage":promoCode.usage,
                "cashback":promoCode.cashback,
                "expirationDate":format(newDate, 'yyyy-MM-dd kk:mm:ss')
            }
            var service = apiUrlService.PutApi(body,myUser.token)
            fetch('https://api.wingo-api.com/api/promoCode/' + promoCode.promoCodeId, service).then((response) => response.json())
                .then((response) => {
                    setLoading(false)
                    if (response.status === true) {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'PromoCode Saved', life: 3000 });
                        getPromoCode()
                        setPromoCodeDialog2(false);
                        setPromoCode(emptyPromoCode);
                    }
                })
          
        }
    }
    const pressCashback=(e)=>{
        let _promoCode = {...promoCode};
        _promoCode['cashback'] = e.checked;
        setPromoCode(_promoCode);
    }
    const cachBackBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'true-icon pi-check-circle': rowData.cashback, 'false-icon pi-times-circle': !rowData.cashback })}></i>;
    }
    const cachBackFilterTemplate = (options) => {
        return <TriStateCheckbox value={options.value} onChange={(e) => options.filterCallback(e.value)} />
    }
    const hideDialog = () => {
        setSubmitted(false);
        setPromoCodeDialog(false);
    }

    const hideDialog2 = () => {
        setPromoCodeDialog2(false);
    }
    const promoCodeDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={savePromoCode} />
        </React.Fragment>
    );

    const promoCodeDialogFooter2 = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog2} />
            <Button label="Edit" icon="pi pi-check" className="p-button-text" onClick={editPromoCode} />
        </React.Fragment>
    );

    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 pl-0">
                            <div className="page-title-box">
                                <h4 className="page-title">Promo Code</h4>
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                    <div className="pt-3" >
                        <Toast ref={toast} />
                        <div className="datatable-responsive-demo">
                            <div className="card ">
                                <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                                <DataTable ref={dt} value={promocodes}
                                    dataKey="softDrink_id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} promoCodes"
                                    globalFilter={globalFilter} header={header} responsiveLayout="scroll">
                                        <Column body={actionEditTemplate} ></Column>
                                    <Column field="code" header="Code" sortable></Column>
                                    <Column field="percentage" header="Percentage (%)" sortable ></Column>
                                    <Column field="cashback" header="Cashback" dataType="boolean" bodyClassName="text-center" style={{ minWidth: '8rem' }} body={cachBackBodyTemplate} filter filterElement={cachBackFilterTemplate} />
                                    <Column field="usage" header="Usage" sortable ></Column>
                                    <Column field="expirationDate" header="Expiration Date" sortable ></Column>
                                </DataTable>
                            </div>
                        </div>

                    </div>
                    <Dialog visible={promoCodeDialog} style={{ width: '450px' }} header="Promo Code Details" modal className="p-fluid" footer={promoCodeDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="name">Code</label>
                            <InputText id="name" value={promoCode.code} onChange={(e) => onInputChange(e, 'code')} required autoFocus className={classNames({ 'p-invalid': submitted && !promoCode.code })} />
                            {submitted && !promoCode.code && <small className="p-error">Code is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">Percentage</label>
                            <InputNumber value={promoCode.percentage} onValueChange={(e) => onInputNumberChange(e, 'percentage')} required prefix="%"   min={0} max={100} mode="decimal" useGrouping={false} className={classNames({ 'p-invalid': submitted && !promoCode.percentage })} />
                            {submitted && !promoCode.percentage && <small className="p-error">Percentage is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">Usage</label>
                            <InputNumber value={promoCode.usage} onValueChange={(e) => onInputNumberChange(e, 'usage')} mode="decimal" useGrouping={false}  />
                            
                        </div>
                        <div className="field">
                            <label htmlFor="name">Expiration Date</label>
                            <Calendar id="basic" value={expirationDate} showTime showSeconds onChange={(e) => setExpDate(e.value)} className={classNames({ 'p-invalid': submitted && !expirationDate })} />
                            {submitted && !expirationDate && <small className="p-error">Expiration Date is required.</small>}
                        </div>
                        <div className="field-checkboxx mt-2 mb-2">
                                <Checkbox inputId="binary" checked={promoCode.cashback} onChange={e => pressCashback(e)}/>
                                <label htmlFor="binary" className='labelcss'>Cashback</label>
                            </div>
                    </Dialog>



             <Dialog visible={deletePromoCodeDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCategoryDialogFooter} onHide={() => setDeletePromoCodeDialog(false)}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                {promoCode && <span>Are you sure you want to delete <b>{promoCode.code}</b>?</span>}
                            </div>
                        </Dialog>

                <Dialog visible={promoCodeDialog2} style={{ width: '450px' }} header="Promo Code Details" modal className="p-fluid" footer={promoCodeDialogFooter2} onHide={hideDialog2}>
                        <div className="field">
                            <label htmlFor="name">Code</label>
                            <InputText id="name" value={promoCode.code} onChange={(e) => onInputChange(e, 'code')} required autoFocus className={classNames({ 'p-invalid': submitted && !promoCode.code })} />
                            {submitted && !promoCode.code && <small className="p-error">Code is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">Percentage</label>
                            <InputNumber value={promoCode.percentage} onValueChange={(e) => onInputNumberChange(e, 'percentage')} required prefix="%"   min={0} max={100} mode="decimal" useGrouping={false} className={classNames({ 'p-invalid': submitted && !promoCode.percentage })} />
                            {submitted && !promoCode.percentage && <small className="p-error">Percentage is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">Usage</label>
                            <InputNumber value={promoCode.usage} onValueChange={(e) => onInputNumberChange(e, 'usage')} required mode="decimal" useGrouping={false} className={classNames({ 'p-invalid': submitted && !promoCode.usage })} />
                           
                        </div>
                        <div className="field">
                           
                            <label htmlFor="name">Expiration Date</label>
                          
                            <Calendar p-inputtext="hi"  id="basic" value={newDate} showTime showSeconds onChange={(e) => setExpirationDate(e.value)} className={classNames({ 'p-invalid': submitted && !newDate })} />
                           
                            {submitted && !newDate && <small className="p-error">Expiration Date is required.</small>}
                        
                            
                        </div>
                        <div className="field-checkboxx mt-2 mb-2">
                                <Checkbox inputId="binary" checked={promoCode.cashback} onChange={e => pressCashback(e)}/>
                                <label htmlFor="binary" className='labelcss'>Cashback</label>
                            </div>
                    </Dialog>

                </div>
            </div >
        </div >
    )
}
function PromoCode() {
    return (
        <>
            <div className="App">
                <SideMenu />
                <PromoCodeList />
            </div>
        </>
    );
}
export default PromoCode;
