import React, { useState, useEffect } from 'react'
import Logo from "../assets/images/logo.png";
import { useHistory } from "react-router-dom";
import { ApiUrlService } from '../api-library/api-services';
import AsyncStorage from '@react-native-async-storage/async-storage';
import sound from "../playsound/notification.mp3"
import useSound from 'use-sound';
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { Badge } from 'primereact/badge';
import { NavLink } from 'react-router-dom';
const SideMenu = () => {
  const history = useHistory();
   const [play] = useSound(sound);
  const logout = () => {
    const data = {
      token: null,
      role: null,
      isLoggedIn: false
    }
    AsyncStorage.setItem("myUser", data)
    history.push("/login");

  }
  const [badge, setBadge] = useState()
  const [isAdmin, setIsAdmin] = useState(false)
  const apiUrlService = new ApiUrlService()

  const MINUTE_MS = 10000;

  const getTotal = async () =>{

           let count = 0;
    const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
    if (myUser.role == "ROLE_ADMIN") {
      setIsAdmin(true)
    }
    else {
      setIsAdmin(false)
    }

      var service = {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + myUser.token
      }
    }

            var dateObj = new Date();
        var month = dateObj.getMonth()<9? '0'+(dateObj.getMonth()+ 1) : dateObj.getMonth()+1;
        var day = dateObj.getDate()<=9 ? '0'+(dateObj.getDate()) : dateObj.getDate();
        var year = dateObj.getUTCFullYear();
        
       var  newdate = year + "/" + month + "/" + day;
     

    fetch('https://api.wingo-api.com/api/OrdersNumber', service).then(res => res.json())
      .then(response => {
        setBadge(response.data)
        count=response.data;
         if(response.data > 0){
                     play();
                }
      });
  }

useEffect(() => {

  getTotal();
  const interval = setInterval( async () => {
    setBadge("0")
    
 
    getTotal();
  
  }, MINUTE_MS);

  return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
}, [])


  return (
    <div
      style={{ height: '100%', position: 'fixed', overflow: 'scroll initial' }}>
      {
        isAdmin ? <CDBSidebar textColor="black" backgroundColor="#008242" >
          <CDBSidebarHeader >
            <img src={Logo} style={{width:200, height:200}} alt="Logo" className="logo"/> 
          </CDBSidebarHeader>
          <CDBSidebarContent className="sidebar-content">
            <CDBSidebarMenu>
              <NavLink exact to="/pendingOrders" activeClassName="activeClicked" >
                <Badge value={badge} className="badgecss" severity="danger"></Badge><CDBSidebarMenuItem icon="history">Pending Orders</CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/orders" activeClassName="activeClicked" >
                <CDBSidebarMenuItem icon="list">Orders</CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/banners" activeClassName="activeClicked" >
                <CDBSidebarMenuItem icon="banner">Banners</CDBSidebarMenuItem>
              </NavLink>
            </CDBSidebarMenu>
          </CDBSidebarContent>
          <CDBSidebarFooter >
            <CDBSidebarMenu>
              <NavLink exact to="/login" onClick={logout}>
                <CDBSidebarMenuItem icon="power-off" >Log out</CDBSidebarMenuItem>
              </NavLink>
            </CDBSidebarMenu>
          </CDBSidebarFooter>
        </CDBSidebar>
          :
          <CDBSidebar textColor="black" backgroundColor="#008242">
             <img src={Logo} style={{width:200, height:150}} alt="Logo" className="logo logoCss" /> 
           
            <CDBSidebarContent className="sidebar-content">
              <CDBSidebarMenu>
                <NavLink exact to="/pendingOrders" activeClassName="activeClicked" >
                  <Badge value={badge} className="badgecss" severity="danger"></Badge><CDBSidebarMenuItem icon="history">Pending Orders</CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/orders" activeClassName="activeClicked" >
                  <CDBSidebarMenuItem icon="list">Orders</CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/banners" activeClassName="activeClicked" >
                <CDBSidebarMenuItem icon="ribbon">Banners</CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/categories" activeClassName="activeClicked" >
                <CDBSidebarMenuItem icon="th-large">Categories</CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/customers" activeClassName="activeClicked" >
                <CDBSidebarMenuItem icon="user">Customers</CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/softDrinks" activeClassName="activeClicked" >
                <CDBSidebarMenuItem icon="cocktail">Soft Drinks</CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/promoCode" activeClassName="activeClicked" >
                <CDBSidebarMenuItem icon="percentage">Promo Code</CDBSidebarMenuItem>
             </NavLink>
              <NavLink exact to="/coupons" activeClassName="activeClicked" >
                  <CDBSidebarMenuItem icon="gift">Coupons</CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/labels" activeClassName="activeClicked" >
                <CDBSidebarMenuItem icon="tag">Labels</CDBSidebarMenuItem>
              </NavLink>
              <NavLink exact to="/notifications" activeClassName="activeClicked" >
                <CDBSidebarMenuItem icon="bell">Notifications</CDBSidebarMenuItem>
              </NavLink>
              </CDBSidebarMenu>
            </CDBSidebarContent>
            <CDBSidebarFooter >
              <CDBSidebarMenu>
                <NavLink exact to="/login" onClick={logout}>
                  <CDBSidebarMenuItem icon="power-off" >Log out</CDBSidebarMenuItem>
                </NavLink>
              </CDBSidebarMenu>
            </CDBSidebarFooter>
          </CDBSidebar>
      }

    </div>
  );
};

export default SideMenu;