import React, {useEffect } from 'react'
import SideMenu from './side-menu';
import Logo from "../assets/images/logo.png";
document.body.style.backgroundImage = `url('')`;
document.body.style.backgroundColor = "#FFFFFF";
const DashboardData=()=>{
    useEffect(() => {
      }, []);
    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 pl-0">
                            <div className="page-title-box">
                                <h4 className="page-title">Dashboard</h4>
                            </div>
                            <div>
                         {/*    <img src={Logo} style={{width:200, height:200}} alt="Logo" className="w-100 logoHeight"/> */}
                            <div className="text-center">
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
            </div>
        </div>
    )
}
export default function Dashboard() {
    return (
        <>
        <div className="App">
            <SideMenu />
            <DashboardData />
        </div>
    </>
    );
}