import React, { useState, useEffect, useRef } from 'react';
import SideMenu from './side-menu';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Steps } from 'primereact/steps';
import { ApiUrlService } from '../api-library/api-services';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Messages } from 'primereact/messages';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
export const CategorieList = () => {
    let emptyProduct = {
        name: '',
        description: '',
        outOfStock:null,
        image: '',
        price: '',
        product_id: '',
        outOfStock: null,
        recommended: null,
        title: '',
        sizeItems: [],
        productAddOn: [
            {
                product_addOn_id: '',
                title: '',
                itemsAddOns: [{ items_addOn_id: '', name: '', price: '' }]
            }]
    };
    let emptyCategory = {
        category_id: '',
        title: '',
        priority: '',
        image: ''
    }

    const msgsError = useRef(null);
    const apiUrlService = new ApiUrlService()
    const [submitted, setSubmitted] = useState(false);
    const [categoryDialog, setCategoryDialog] = useState(false);
    const [recommendedProductDialog, setRecommendedProductDialog] = useState(false)
    const [products, setProducts] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);
    const [loading, setLoading] = useState(false);
    const [readyOnlyStatus, setReadyOnlyStatus] = React.useState(true);
    const [deleteCategoryDialog, setDeleteCategoryDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [itemsDialog, setAddItemsDialog] = useState(false);
    const toast = useRef(null);
    const [customize_id, setCustomize_id] = useState("")
    const [itemsList, setItemsList] = useState([{ price: "", name: "" }]);
    const [disableSave, setDisableSave] = React.useState(false);
    const interactiveItems = [{ label: 'Title' }, { label: 'Add Items' }]
    const [globalFilter, setGlobalFilter] = useState('');
    const [restaurant_id, setRestaurantID] = useState("");
    const [priceProduct, setPriceProduct] = useState("");
    const [sizeProduct, setSizeProduct] = useState("");
    const [imageDialog, setImageDialog] = useState(false);
    const [imageTitle, setImageTitle] = useState('');
    const [srcImage, setSrcImage] = useState('');
    const [productDialog, setProductDialog] = useState(false);
    const [sizeDialog, setSizeDialog] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0);
    const [product, setProduct] = useState(emptyProduct);
    const [category, setCategory] = useState(emptyCategory);
    const [addTitle, setAddTitle] = useState("");
    const [productAddOn, setProductAddOn] = useState([])
    const [productSizes, setProductSizes] = useState([])
    const [recommendedProducts, setRecommendedProducts] = useState([])

    const dt = useRef(null);
    const [productDetails, setProductDetails] = useState([])
    const getMenu = () => {
        setProducts([])
        setLoading(true)
        var service = apiUrlService.GetApi()
        fetch('https://api.wingo-api.com/api/category', service).then(res => res.json())
            .then(response => {
                setProducts(response.data)
                setLoading(false)

            });
    }
    useEffect(() => {
        getMenu()

    }, []);
    const pressStock=async(e)=>{
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
        let _product = {...product};
        _product['outOfStock'] = e.checked;
        setProduct(_product);
        const body = {
        }
        var service = apiUrlService.PutApi(body,myUser.token);
        fetch('https://api.wingo-api.com/api/outOfStock/' + product.product_id + '?out=' + e.checked, service).then(res => res.json())
            .then(response => {
                if (response.status === true) {
                    
                }
            })
    }
    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const hideDialogImage = () => {
        setImageDialog(false)
    }
    const onChangePicture = async (e) => {
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
        const image = e.target.files[0];
        const formData = new FormData();
        formData.append('file', image);
        await axios.post('https://api.wingo-api.com/api/saveImage', formData, { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer '+myUser.token  } })
            .then(function (response) {
                category.image = response.data.data;
                setCategory({ ...category })
            })

    };
    const onChangePictureProduct = async (e) => {
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
        const image = e.target.files[0];
        const formData = new FormData();
        formData.append('file', image);
        await axios.post('https://api.wingo-api.com/api/saveImage', formData, { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer '+myUser.token  } })
            .then(function (response) {
                product.image = response.data.data;
                setProduct({ ...product })
            })

    };
    const openImage = (name, src) => {
        setImageDialog(true)
        setSrcImage(src)
        setImageTitle(name)
    }
    const actionImageTemplate = (rowData) => {
        return (
            <React.Fragment>
                <img src={rowData.image} className="productImage" onClick={e => openImage(rowData.name, rowData.image)} />
            </React.Fragment>
        );

    }
    const openNewProduct = (data) => {
        setProduct(emptyProduct);
        setProductDetails(data)
        setProductDialog(true)
    }
    const leftToolbarTemplate = (data) => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 marginrightbtn" onClick={() => openNewProduct(data)} />
            </React.Fragment>
        )
    }
    const getRecommendedProduct = () => {
        var service = apiUrlService.GetApi()
        fetch('https://api.wingo-api.com/api/productsRecommended', service).then(res => res.json())
            .then(response => {
                setRecommendedProducts(response.data)
                setRecommendedProductDialog(true)


            });
    }
    const leftMenuToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New Category" icon="pi pi-plus" className="p-button-success p-mr-2 marginrightbtn" onClick={() => setCategoryDialog(true)} />
                <Button label="Recommended Products" icon="pi pi-check" className="p-button-info p-mr-2 marginrightbtn" onClick={() => getRecommendedProduct()} />

            </React.Fragment>
        )
    }
    const editProduct = (product) => {
        setProduct(emptyProduct);
        setProduct({ ...product });
        setProductAddOn(product.productAddOn)
        setProductDialog(true);
    }
    const confirmDeleteProduct = (rowData) => {
        setProduct(rowData);
        setDeleteProductDialog(true);
    }
    const deleteCategory = async() => {
        setLoading(true)
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))

        if (category.category_id) {
            var service = apiUrlService.DeleteApi(myUser.token);
            fetch('https://api.wingo-api.com/api/category/' + category.category_id + '', service).then(res => res.json())
                .then(response => {
                    setLoading(false)
                    if (response.status === true) {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Category Deleted Succesfully', life: 3000 });
                        getMenu(restaurant_id)
                        setDeleteCategoryDialog(false);
                        setCategory(emptyCategory);
                    }
                })
        }
        else {
      
        }

    }
    const deleteProduct=async()=>{
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))

        var service = apiUrlService.DeleteApi(myUser.token);
        fetch('https://api.wingo-api.com/api/product/' + product.product_id + '', service).then(res => res.json())
            .then(response => {
                setLoading(false)
                if (response.status === true) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted Succesfully', life: 3000 });
                    getMenu(restaurant_id)
                    setDeleteProductDialog(false);
                    setCategory(emptyCategory);
                }
            })
    }
    const hideDeleteDialog = () => {
        setCategory(emptyCategory)
        setProduct(emptyProduct)
        setDeleteCategoryDialog(false)
        setDeleteProductDialog(false)
    }
    const deleteCategoryDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteCategory} />
        </React.Fragment>
    );
    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </React.Fragment>
    );
    const addItems = (rowData) => {
        setAddItemsDialog(true)
        setProduct({ ...rowData })
        setItemsList([])

    }
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>  <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteProduct(rowData)} />
            </React.Fragment>
        );
    }

    const actionBodyItemsTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-plus" label="Items" className="p-button-rounded p-button-info" onClick={() => addItems(rowData)} />
            </React.Fragment>
        );
    }
    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">
                <h5>Products for {data.category}</h5>
                <Toolbar className="p-mb-4" left={() => leftToolbarTemplate(data)} ></Toolbar>
                <DataTable value={data.products} responsiveLayout="scroll">
                    <Column field="name" header="Name" sortable></Column>
                    <Column field="description" header="Description" sortable></Column>
                    <Column field="price" header="Price" sortable></Column>
                    <Column field="image" header="Image" body={actionImageTemplate}></Column>
                    <Column body={actionBodyItemsTemplate}></Column>
                    <Column body={actionBodyTemplate}></Column>
                </DataTable>
            </div>
        );
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;
        setProduct(_product);
        setCategory(_product)
    }

    const onInputCategoryChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...category };
        _product[`${name}`] = val;
        setCategory(_product)
    }
    const saveProduct = async() => {
        setSubmitted(true)
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))

        if (product.name && product.price) {
            setLoading(true)
            if (product.product_id) {
                const body =
                {
                    "name": product.name,
                    "description": product.description,
                    "price": product.price,
                    "image": product.image,
                    "outOfStock": product.outOfStock,
                    "recommended": product.recommended
                }
                var service = apiUrlService.PutApi(body,myUser.token)
                fetch('https://api.wingo-api.com/api/product/' + product.product_id + '', service).then((response) => response.json())
                    .then((response) => {
                        setLoading(false)
                        if (response.status === true) {
                            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });
                            setSubmitted(false)
                            setLoading(false)
                            setProductDialog(false)
                            getMenu(restaurant_id)
                        }
                    })
            }
            else {
                const body =
                {
                    "name": product.name,
                    "description": product.description,
                    "price": product.price,
                    "image": product.image,
                    "outOfStock": product.outOfStock,
                    "recommended": product.recommended,
                    "category": {
                        "category_id": productDetails.category_id
                    },
                }
                var service = apiUrlService.PostApi(body,myUser.token)
                fetch('https://api.wingo-api.com/api/product', service).then((response) => response.json())
                    .then((response) => {
                        setLoading(false)
                        if (response.status === true) {
                            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });
                            setSubmitted(false)
                            setLoading(false)
                            setProductDialog(false)
                            getMenu(restaurant_id)

                        }
                    })
            }

        }

    }
    const dialogFuncMap = {
        'itemsDialog': setAddItemsDialog,
    }
    const onHideItems = (name) => {
        setActiveIndex(0)
        if (readyOnlyStatus === true) {
            dialogFuncMap[`${name}`](false);
            setSubmitted(false);
            setReadyOnlyStatus(true)
        }
        else {
            msgsError.current.show([
                { severity: 'warn', summary: 'Warning ', detail: '  Add Items!', sticky: true },
            ]);
        }
    }
    const saveCategory =async () => {
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))

        setSubmitted(true)
        if (category.title && category.priority) {
            setSubmitted(false)
            setLoading(true)
            if (category.category_id) {
                const body =
                {
                    "title": category.title,
                    "priority": category.priority,
                    "image": category.image
                }
                var service = apiUrlService.PutApi(body,myUser.token)
                fetch('https://api.wingo-api.com/api/category/' + category.category_id + '', service).then((response) => response.json())
                    .then((response) => {
                        setLoading(false)
                        setSubmitted(false)
                        if (response.status === true) {
                            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Category Updated Successfully', life: 3000 });
                            setCategoryDialog(false)
                            getMenu()

                        }
                    })
            }
            else {
                const body =
                {
                    "title": category.title,
                    "priority": category.priority,
                    "image": category.image
                }
                var service = apiUrlService.PostApi(body,myUser.token)
                fetch('https://api.wingo-api.com/api/category', service).then((response) => response.json())
                    .then((response) => {
                        setLoading(false)
                        if (response.status === true) {
                            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Category Created Successfully', life: 3000 });
                            setSubmitted(false)
                            setLoading(false)
                            setCategoryDialog(false)
                            getMenu()
                        }
                    })
            }
        }
    }
    const saveItems = async() => {
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
        setSubmitted(true)
        if (itemsList.length === 1) {
            toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Please Add Items', life: 3000 });
        }
        else {
            itemsList.pop()
            const body = itemsList
            var service = apiUrlService.PostApi(body,myUser.token)
            fetch('https://api.wingo-api.com/api/itemsAddOn', service).then((response) => response.json())
                .then((response) => {
                    if (response.status === true) {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Items Added Successfully', life: 3000 });
                        getMenu()
                        setSubmitted(false)
                        setReadyOnlyStatus(false)
                        setItemsList([...itemsList, { price: '', name: '', productAddOn: { product_addOn_id: '' } }]);
                        setDisableSave(false)
                        setActiveIndex(0)
                    }
                    setAddItemsDialog(false)
                })
        }
    }
    const saveTitle = async() => {
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
        setSubmitted(true)
        if (addTitle) {
            setSubmitted(false)
            setDisableSave(true)
            const body = {
                "title": addTitle,
            }
            var service = apiUrlService.PostApi(body,myUser.token)
            fetch('https://api.wingo-api.com/api/productAddOn', service).then((response) => response.json())
                .then((response) => {
                    if (response.status === true) {
                        fetch('https://api.wingo-api.com/api/addToProduct?product='+product.product_id+'&add='+response.data.product_addOn_id, service).then((response) => response.json())
                            .then((response) => {
                                if (response.status === true) {
                                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Title Created', life: 3000 });
                                    setDisableSave(true);
                                    setReadyOnlyStatus(false)
                                    setCustomize_id(response.data.product_addOn_id)
                                    setItemsList([...itemsList, { price: '', name: '', productAddOn: { product_addOn_id: response.data.product_addOn_id } }]);
                                    setAddTitle('')
                                }
                            })
                    }
                })
        }

    }
    const productDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => setProductDialog(false)} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </React.Fragment>
    );
    const categoryDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => setCategoryDialog(false)} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveCategory} />
        </React.Fragment>
    )
    const editCategory = (rowData) => {
        setCategoryDialog(true)
        setCategory({ ...rowData });
    }
    const confirmDeleteCategory = (rowData) => {
        setCategory(rowData);
        setDeleteCategoryDialog(true);
    }
    const actionCategoryBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => editCategory(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteCategory(rowData)} />
            </React.Fragment>
        );
    }
    const handleRemoveClick = index => {
        const list = [...itemsList];
        list.splice(index, 1);
        setItemsList(list);
    };
    const handleRemoveEditClick = async(data, dataItems, ind, index) => {
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))

        const list = [...data.itemsAddOns];
        list.splice(index, 1);
        data.itemsAddOns = list;
        productAddOn[ind] = data
        setProductAddOn(productAddOn)
        setProduct({ ...product });
        var service = apiUrlService.DeleteApi(myUser.token);
        fetch('https://api.wingo-api.com/api/AddedItems/' + dataItems.items_addOn_id + '', service).then(res => res.json())
            .then(response => {
                if (response.status === true) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Item Deleted', life: 3000 });
                }
            })

    };
    const handleAddClick = (data) => {
        if (!data.price) {
            data.price = null;
        }
        setItemsList([...itemsList, { price: '', name: '', productAddOn: { product_addOn_id: customize_id } }]);
    };
    const handleEditAddClick = async(data, dataitem, index) => {
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
        const list = [...data.itemsAddOns];
        list.push({ price: '', name: '' })
        productAddOn[index].itemsAddOns = list
        setProductAddOn(productAddOn)
        setProduct({ ...product });
        if (!dataitem.items_addOn_id) {
            var price;
            if (!dataitem.price) {
                price = null
            }
            else {
                price = dataitem.price
            }
            const body = [{
                "name": dataitem.name,
                "price": price,
                "productAddOn":
                {
                    "product_addOn_id": data.product_addOn_id
                }
            }]
            var service = apiUrlService.PostApi(body,myUser.token)
            fetch('https://api.wingo-api.com/api/AddedItems/', service).then((response) => response.json())
                .then((response) => {
                    if (response.status === true) {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Items Added Successfully', life: 3000 });
                    }
                })
        }

    };
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...itemsList];
        list[index][name] = value;
        setItemsList(list);
    };
    const handleInputEditChange = (e, data, index) => {
        const { name, value } = e.target;
        const list = [...data.itemsAddOns];
        list[index][name] = value;
        setItemsList(list);
    };
    const handleInputTitleChange = (e, index) => {
        const list = [...productAddOn]
        list[index].title = e.target.value;
        setProductAddOn(list)
    };
    const exportCSV = () => {
        dt.current.exportCSV();
    }
    const handleInputPriceChange = (e, index) => {
        const list = [...productSizes]
        list[index].price = e.target.value;
        setProductSizes(list)
    }
    const handleEditSaveClick = async(data) => {
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))

        var price;
        if (!data.price) {
            price = null
        }
        else {
            price = data.price
        }
        const body =
        {
            "name": data.name,
            "price": price,
        }
        var service = apiUrlService.PutApi(body,myUser.token)
        fetch('https://api.wingo-api.com/api/itemsAddOn/' + data.items_addOn_id + '', service).then((response) => response.json())
            .then((response) => {
                setLoading(false)
                if (response.status === true) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Item Updated', life: 3000 });

                }
            })
    };
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }
    const handleRemoveTitleClick = async(data, index) => {
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))

        const list = [...productAddOn];
        list.splice(index, 1);
        setProductAddOn(list);
        setProduct({ ...product });
        var service = apiUrlService.DeleteApi(myUser.token);
        fetch('https://api.wingo-api.com/api/productAddOn/' + data.product_addOn_id + '', service).then(res => res.json())
            .then(response => {
                if (response.status === true) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Title Deleted', life: 3000 });
                }
            })
    }
    const handleEditTitleClick = async(data, index) => {
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))

        const list = [...productAddOn];
        setProductAddOn(list);
        setProduct({ ...product });
        const body = {
            "title": data.title,

        }
        var service = apiUrlService.PutApi(body,myUser.token);
        fetch('https://api.wingo-api.com/api/productAddOn/' + data.product_addOn_id + '', service).then(res => res.json())
            .then(response => {
                if (response.status === true) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Title Updated', life: 3000 });
                }
            })
    }

    const pressRecommended = (e) => {
        let _product = { ...product };
        _product['recommended'] = e.checked;
        setProduct(_product);
    }
    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 pl-0">
                            <div className="page-title-box">
                                <h4 className="page-title">Categories and Products</h4>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Toast ref={toast} />
                        <div className="card">
                            <Toolbar className="p-mb-4" left={leftMenuToolbarTemplate} ></Toolbar>
                            <DataTable ref={dt} value={products} scrollable scrollHeight="340px" rows={20} loading={loading}
                                paginator rowsPerPageOptions={[5, 10, 25]} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                                rowExpansionTemplate={rowExpansionTemplate}
                                globalFilter={globalFilter}>
                                <Column expander style={{ width: '3em' }} />
                                <Column field="title" header="Category" sortable />
                                <Column field="image" header="Image" body={actionImageTemplate} sortable />
                                <Column body={actionCategoryBodyTemplate}></Column>
                            </DataTable>
                        </div>
                        <Dialog visible={imageDialog} style={{ width: '450px' }} header={imageTitle} modal className="p-fluid" onHide={hideDialogImage}>
                            <div className="datatable-responsive-demo">
                                <div className="card ">
                                    <img style={{ width: '400px' }} src={srcImage} />
                                </div>
                            </div>
                        </Dialog>
                        <Dialog visible={deleteCategoryDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCategoryDialogFooter} onHide={() => setDeleteCategoryDialog(false)}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                {category && <span>Are you sure you want to delete <b>{category.title}</b>?</span>}
                            </div>
                        </Dialog>
                        <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={() => setDeleteCategoryDialog(false)}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                {category && <span>Are you sure you want to delete <b>{product.name}</b>?</span>}
                            </div>
                        </Dialog>
                        <Dialog visible={productDialog} header="Product Details" style={{ width: '450px' }} footer={productDialogFooter} modal className="p-fluid" onHide={() => setProductDialog(false)}>
                            <div className="p-field">
                                <label htmlFor="motoType">Name</label>
                                <InputText value={product.name} onChange={(e) => onInputChange(e, 'name')} required s className={classNames({ 'p-invalid': submitted && !product.name })} />
                            </div>
                            <div className="p-field">
                                <label htmlFor="phoneNumber">Description</label>
                                <InputText value={product.description}
                                    onChange={(e) => onInputChange(e, 'description')} />

                            </div>
                            <div className="field">
                                <label htmlFor="phoneNumber">Price</label>
                                <InputNumber value={product.price} onValueChange={(e) => onInputChange(e, 'price')} mode="decimal" minFractionDigits={1} maxFractionDigits={5} currency="EUR"  className={classNames({ 'p-invalid': submitted && !product.price })} />
                            </div>
                            <div className="field-checkboxx mt-2 mb-2">
                                <Checkbox inputId="binary" checked={product.recommended} onChange={e => pressRecommended(e)} />
                                <label htmlFor="binary" className='labelcss'>Recommended</label>
                            </div>
                            <div className="field-checkboxx mt-2">
                                <Checkbox inputId="binary" checked={product.outOfStock} onChange={e => pressStock(e)} />
                                <label htmlFor="binary" className='labelcss'>Out of Stock</label>
                            </div>
                            <div className='p-field'>
                                <label htmlFor="phoneNumber">Upload Image</label>
                                <input id="profilePic" type="file" onChange={onChangePictureProduct} />
                            </div>
                            <div className="field">
                                <label htmlFor="phoneNumber">Image Link</label>
                                <InputText value={product.image} onChange={(e) => onInputChange(e, 'image')} />
                            </div>

                            {product.product_id && <div>
                                <div className='p-field'>
                                    {productAddOn.map((x, i) => {
                                        return (
                                            <div className="col-12 col-md-9 col-sm-5 col-lg-12 mr-1 mb-1 pl-0  mt-2">
                                                <div className="p-field pr-2 ">
                                                    <label htmlFor="name">Title</label>
                                                    <div className='d-flex'>
                                                        <InputText name="title" value={x.title} onChange={e => handleInputTitleChange(e, i)} required className={classNames({ 'p-invalid': submitted && !x.title })} />
                                                        <i className="pi pi-check ml-1 mr-1 pt-3" style={{ 'fontSize': '1em' }} onClick={() => handleEditTitleClick(x, i)}></i>
                                                        <i className="pi pi-trash ml-1 mr-1 pt-3" style={{ 'fontSize': '1em' }} onClick={() => handleRemoveTitleClick(x, i)}></i>
                                                    </div>
                                                </div>
                                                {x.itemsAddOns.map((y, t) => {
                                                    return (
                                                        <div className="col-12 col-md-9 col-sm-5 col-lg-12 mr-1 mb-1 d-flex">
                                                            <div className="p-field pr-2">
                                                                <label htmlFor="name">Name</label>
                                                                <InputText name="name" value={y.name} onChange={e => handleInputEditChange(e, x, t)} required className={classNames({ 'p-invalid': submitted && !y.name })} />
                                                            </div>
                                                            <div className="p-field ">
                                                                <label htmlFor="name">Price</label>
                                                                <InputNumber name="price" value={y.price} onValueChange={(e) => handleInputEditChange(e, x, t)} mode="currency" currency="EUR" locale="de-DE" required className={classNames({ 'p-invalid': submitted && !y.price })} />
                                                            </div>
                                                            <i className="pi pi-check ml-1 mr-1 pt-5" style={{ 'fontSize': '1em' }} onClick={() => handleEditSaveClick(y)}></i>
                                                            {x.itemsAddOns.length !== 1 && <i className="pi pi-trash ml-1 mr-1 pt-5" style={{ 'fontSize': '1em' }} onClick={() => handleRemoveEditClick(x, y, i, t)}></i>}
                                                            {x.itemsAddOns.length - 1 === t && <i className="pi pi-plus-circle pt-5" style={{ 'fontSize': '1em' }} onClick={() => handleEditAddClick(x, y, i)}></i>}

                                                        </div>)
                                                })}
                                            </div>
                                        );
                                    })}
                                </div>

                            </div>

                            }

                        </Dialog>

                        <Dialog visible={itemsDialog} style={{ width: '450px' }} header="Add Items" modal className="p-fluid" onHide={() => onHideItems('itemsDialog')}  >
                            <Messages ref={msgsError} />
                            <Steps model={interactiveItems} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={readyOnlyStatus} />
                            {activeIndex === 0 && <div>
                                <div className="p-field ">
                                    <label htmlFor="name">Name</label>
                                    <InputText id="name" value={addTitle} onChange={(e) => setAddTitle(e.target.value)} required className={classNames({ 'p-invalid': submitted && !addTitle })} />
                                </div>
                                <div className="d-flex float-right">
                                    <Button label="Save" icon="pi pi-check" disabled={disableSave} className="p-button-raised p-button-warning" onClick={saveTitle} />
                                </div>
                            </div>}
                            {activeIndex === 1 && <div>
                                {itemsList.map((x, i) => {
                                    return (
                                        <div className="col-12 col-md-9 col-sm-5 col-lg-12 mr-1 mb-1 d-flex ">
                                            <div className="p-field pr-2">
                                                <label htmlFor="name">Name</label>
                                                <InputText name="name" value={x.name} onChange={e => handleInputChange(e, i)} required className={classNames({ 'p-invalid': submitted && !x.name })} />
                                            </div>
                                            <div className="p-field ">
                                                <label htmlFor="name">Price</label>
                                                <InputNumber name="price" value={x.price} onValueChange={(e) => handleInputChange(e, i)} mode="currency" currency="EUR" locale="de-DE"/>
                                            </div>
                                            {itemsList.length !== 1 && <i className="pi pi-trash ml-1 mr-1 pt-5" style={{ 'fontSize': '1em' }} onClick={() => handleRemoveClick(i)}></i>}
                                            {itemsList.length - 1 === i && <i className="pi pi-plus-circle pt-5" style={{ 'fontSize': '1em' }} onClick={() => handleAddClick(x)}></i>}

                                        </div>
                                    );
                                })}
                                <div className="d-flex float-right">
                                    <Button label="Save" icon="pi pi-check" className="p-button-raised p-button-warning" onClick={saveItems} />
                                </div>
                            </div>}

                        </Dialog>
                        <Dialog visible={categoryDialog} header="Category Details" style={{ width: '450px' }} footer={categoryDialogFooter} modal className="p-fluid" onHide={() => setCategoryDialog(false)}>
                            <div className="p-field">
                                <label htmlFor="motoType">Category</label>
                                <InputText value={category.title} onChange={(e) => onInputCategoryChange(e, 'title')} required s className={classNames({ 'p-invalid': submitted && !category.title })} />
                            </div>
                            <div className="field">
                                <label htmlFor="phoneNumber">Priority</label>
                                <InputNumber value={category.priority} onValueChange={(e) => onInputCategoryChange(e, 'priority')} mode="decimal" useGrouping={false} />
                            </div>
                            <div className='p-field'>
                                <label htmlFor="phoneNumber">Upload Image</label>
                                <input id="profilePic" type="file" onChange={onChangePicture} />
                            </div>
                            <div className="field">
                                <label htmlFor="phoneNumber">Image Link</label>
                                <InputText value={category.image} />
                            </div>
                        </Dialog>
                        <Dialog visible={recommendedProductDialog} header="Recommended Products" style={{ width: '80%' }} modal className="p-fluid" onHide={() => setRecommendedProductDialog(false)}>
                            <div className="card ">
                                <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>

                                <DataTable ref={dt} value={recommendedProducts}
                                    dataKey="softDrink_id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} promoCodes"
                                    globalFilter={globalFilter} header={header} responsiveLayout="scroll">
                                    <Column field="name" header="Name" sortable></Column>
                                    <Column field="description" header="Description" sortable></Column>
                                    <Column field="price" header="Price" sortable></Column>
                                    <Column field="image" header="Image" body={actionImageTemplate}></Column>
                                </DataTable>
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Categories() {
    return (
        <>
            <div className="App">
                <SideMenu />
                <CategorieList />
            </div>
        </>
    );
}
export default Categories;
