import React, { useState, useEffect } from 'react';

import { Switch, Redirect } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Login from './app/login';
import Dashboard from './app/Dashboard';
import Home from './app/Home';
import orders from './app/orders';
import Banners from './app/Banners';
import PendingOrders from './app/PendingOrders';
import Categories from './app/Categories';
import Customers from './app/Customers';
import sound from "./playsound/notification.mp3"
import Notification from './app/Notifications';
import { ApiUrlService } from './api-library/api-services';
import SoftDrinks from './app/SoftDrinks';
import PromoCode from './app/PromoCode';
import Coupons from './app/Coupons'
import Labels from './app/Labels';
import PrimeReact from 'primereact/api';
import './App.css';
import 'primeicons/primeicons.css';
import useSound from 'use-sound';
import { initializeApp } from 'firebase/app';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { getMessaging, getToken, onMessage} from 'firebase/messaging';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries



PrimeReact.ripple = true;
function App() {
   const [play] = useSound(sound);
   var firebaseConfig = {
  apiKey: "AIzaSyDsoTwwMqMSKaAd2iOCLsMrNBA0OEXPhX4",
    authDomain: "beitelzaytoun-notifications.firebaseapp.com",
    projectId: "beitelzaytoun-notifications",
    storageBucket: "beitelzaytoun-notifications.appspot.com",
    messagingSenderId: "748672073908",
    appId: "1:748672073908:web:7d2b3658d2464ba78697e1",
    measurementId: "G-1P2NPDLKFV"
};

initializeApp(firebaseConfig);
const messaging = getMessaging();
  const apiUrlService = new ApiUrlService()
const [show,setShow]=useState(false);
const[notification,setNotification]=useState({title:"",body:""})



  useEffect(() => {
   
   // fetchData();

requestForToken();



  }, []);


 const requestForToken = async () => {
   const myUser = await AsyncStorage.getItem('myUser')
   console.log(JSON.parse(myUser))
    if (myUser) {
       console.log(1)
return getToken(messaging, { vapidKey: "BNdB79rWbLOqEk05O-wuPVLlZDz7SXobTryGIJWzB1aQT2waEg7Sg8o6gZEbhedJ9j4zXXVzVIzIP-kACKN1vZM" })
    .then((currentToken) => {

      if (currentToken) {
        console.log('current token for client: ', currentToken);
           const body = {
            token: currentToken
        }
  
         var service2 = apiUrlService.GetApi();
         
        fetch('https://api.wingo-api.com/api/userByEmail?email='+JSON.parse(myUser).email, service2).then(res => res.json())
            .then(data => {
   
                var service = apiUrlService.PutApi(body,JSON.parse(myUser).token);
        fetch('https://api.wingo-api.com/api/updateToken/'+data.data.customer_id, service).then(res => res.json())
            .then(response => {
                if (response.status === true) {
                    
                }
            })
            })
       
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        alert('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      //alert('An error occurred while retrieving token. ', err);
    });

    return getToken(messaging, { vapidKey: "BNdB79rWbLOqEk05O-wuPVLlZDz7SXobTryGIJWzB1aQT2waEg7Sg8o6gZEbhedJ9j4zXXVzVIzIP-kACKN1vZM" })
    .then((currentToken) => {

      if (currentToken) {
        console.log('current token for client: ', currentToken);
           const body = {
            token: currentToken
        }
  
         var service2 = apiUrlService.GetApi();
         
        fetch('https://api.wingo-api.com/api/userByEmail?email='+JSON.parse(myUser).email, service2).then(res => res.json())
            .then(data => {
   
                var service = apiUrlService.PutApi(body,JSON.parse(myUser).token);
        fetch('https://api.wingo-api.com/api/updateToken/'+data.data.customer_id, service).then(res => res.json())
            .then(response => {
                if (response.status === true) {
                    
                }
            })
            })
       
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        alert('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      alert('An errorr occurred while retrieving token. ', err);
    });
      }
      else {
      
      }
  
};
  


  const AfterLogin = () => (
    <div className="heightCss">
      <Switch>
         <Route path='/home' component={Home} />
          <Route path='/' exact> <Redirect to='/home' /> </Route>
          <Route path='/login' component={Login} />
          <Route path='/dashboard' component={Dashboard} />
          <Route path='/pendingOrders' component={PendingOrders} />
          <Route path='/orders' component={orders} />
          <Route path='/banners' component={Banners} />
          <Route path='/categories' component={Categories} />
          <Route path='/customers' component={Customers} />
          <Route path='/softDrinks' component={SoftDrinks} />
          <Route path='/promoCode' component={PromoCode} />
          <Route path='/labels' component={Labels} />
          <Route path='/coupons' component={Coupons} />
          <Route path='/notifications' component={Notification} />
        </Switch>
    </div>

  )
  return (
    <div > 
   
      <div className="heightCss"> 
     <Switch>
         <Route path='/home' component={Home} />
          <Route path='/' exact> <Redirect to='/home' /> </Route>
          <Route path='/login' component={Login} />
          <Route path='/dashboard' component={Dashboard} />
          <Route path='/pendingOrders' component={PendingOrders} />
          <Route path='/orders' component={orders} />
          <Route path='/banners' component={Banners} />
          <Route path='/categories' component={Categories} />
          <Route path='/customers' component={Customers} />
          <Route path='/softDrinks' component={SoftDrinks} />
          <Route path='/promoCode' component={PromoCode} />
          <Route path='/labels' component={Labels} />
          <Route path='/coupons' component={Coupons} />
          <Route path='/notifications' component={Notification} />
      </Switch>
    </div>
    </div>
  );
}
export default App;
