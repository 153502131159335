import React, { useState, useEffect, useRef } from 'react';
import SideMenu from './side-menu';
import { InputText } from 'primereact/inputtext';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const NotificationsDiv = () => {
    const toast = useRef(null);
    const [customers, setCustomers] = useState([])
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [titleText, setTitleText] = useState('')
    const [contentText, setContentText] = useState('')
    const [titleMultiText, setTitleMultiText] = useState('')
    const [contentMultiText, setContentMultiText] = useState('')
    const onChangeCustomer = (e) => {
        setSelectedCustomer(e.value);

    }
    useEffect(() => {
        getCustomers()
    }, []);

    const getCustomers = async () => {
        setCustomers([])
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
        var service = {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + myUser.token
            }
        }
        fetch('https://api.wingo-api.com/api/users', service).then(res => res.json())
            .then(response => {
                customers.length = 0
                for (var j = 0; j < response.data.length; j++) {
                    const data = {
                        label: response.data[j].fullName,
                        value: response.data[j].customer_id,
                        token: response.data[j].token,
                    }
                    customers.push(data)
                }
                setCustomers(customers);
            });
    }
    const sendNotification = async () => {
        const found = customers.find(element => element.value === selectedCustomer);

        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
        var body = {
            "subject": titleText,
            "content": contentText,
            "sound": "default",
            "image": "https://somedomain.com/example.jpg",
            "data": {
                "key1": "Value 1",
                "key2": "Value 2",
                "key3": "Value 3",
                "key4": "Value 4"
            }
        }
        var service = {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + myUser.token
            },
            body: JSON.stringify(body)
        }
        try {
            fetch('https://api.wingo-api.com/api/send-notification?token=' + found.token, service).then(res => res.json())
                .then(response => {
                });
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Notification Sent Succesfully', life: 3000 });

        }
        catch (err) {
            alert("Error sending notification !")
        }

    }
    const sendNotifications = async () => {


        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
        var body = {
            "subject": titleMultiText,
            "content": contentMultiText,
            "sound": "default",
            "image": "https://somedomain.com/example.jpg",
            "data": {
                "key1": "Value 1",
                "key2": "Value 2",
                "key3": "Value 3",
                "key4": "Value 4"
            }
        }
        var service = {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + myUser.token
            },
            body: JSON.stringify(body)
        }
        try {
            fetch('https://api.wingo-api.com/api/send-multiple', service).then(res => res.json())
                .then(response => {
                });
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Notification Sent Succesfully', life: 3000 });

        }
        catch (err){
            alert("Error sending notification !")

        }
    }
    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 pl-0">
                            <div className="page-title-box">
                                <h4 className="page-title">Notifications</h4>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <Toast ref={toast} />
                        <Accordion activeIndex={0}>
                            <AccordionTab header="Single Notification">
                                <div className="p-field">
                                    <Dropdown filter showClear options={customers} value={selectedCustomer} onChange={(e) => onChangeCustomer(e)} placeholder="Customer" />
                                </div>
                                <div className="p-field">
                                    <InputText value={titleText} onInput={(e) => setTitleText(e.target.value)} placeholder="Title" />

                                </div>
                                <div className="p-field">
                                    <InputText value={contentText} onInput={(e) => setContentText(e.target.value)} placeholder="Content" />

                                </div>
                                <div className="p-field">
                                    <Button label="Send" icon="pi pi-check" className="p-button-success" onClick={sendNotification} />
                                </div>

                            </AccordionTab>
                            <AccordionTab header="Multiple Notifications">
                                <div className="p-field">
                                    <InputText value={titleMultiText} onInput={(e) => setTitleMultiText(e.target.value)} placeholder="Title" />

                                </div>
                                <div className="p-field">
                                    <InputText value={contentMultiText} onInput={(e) => setContentMultiText(e.target.value)} placeholder="Content" />

                                </div>
                                <div className="p-field">
                                    <Button label="Send" icon="pi pi-check" className="p-button-success" onClick={sendNotifications} />
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Notification() {
    return (
        <>
            <div className="App">
                <SideMenu />
                <NotificationsDiv />
            </div>
        </>
    );
}
export default Notification;

