import React, { useState, useEffect, useRef } from 'react';
import SideMenu from './side-menu';
import { Galleria } from 'primereact/galleria';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { DataScroller } from 'primereact/datascroller';
import { InputText } from 'primereact/inputtext';
import { ApiUrlService } from '../api-library/api-services';
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';

const BannersList = () => {
    const [images, setImages] = useState(null);
    const [image, setImage] = useState(null)
    const toast = useRef(null);
    const apiUrlService = new ApiUrlService()
    const [activeIndex, setActiveIndex] = useState(0)
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false)
    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '960px',
            numVisible: 4
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];
    const [bannerDialog, setBannerDialog] = useState(false)
    const getBanners = () => {
        var service = apiUrlService.GetApi();
        fetch('https://api.wingo-api.com/api/banners', service).then(res => res.json())
            .then(data => setImages(data.data));
    }
    useEffect(() => {
        getBanners()
        var service = apiUrlService.GetApi();
        fetch('https://api.wingo-api.com/api/products', service).then(res => res.json())
            .then(response => {
                products.length = 0
                for (var j = 0; j < response.data.length; j++) {
                    const data = {
                        label: response.data[j].name,
                        value: response.data[j].product_id
                    }
                    products.push(data)
                }
                setProducts(products);
            });
    }, []);

    const itemTemplate = (item) => {
        return <img src={item.image} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} style={{ width: '100%', display: 'block' }} />
    }

    const thumbnailTemplate = (item) => {
        return <img src={item.image} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} style={{ width: '300px', display: 'block' }} />
    }
    const saveBanner = async() => {
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
        if (image && selectedProduct) {
            const body =
            {
                "image": image,
                "productId": selectedProduct,
            }
            var service = apiUrlService.PostApi(body,myUser.token)
            fetch('https://api.wingo-api.com/api/banners', service).then((response) => response.json())
                .then((response) => {
                    if (response.status === true) {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Image Created Successfully', life: 3000 });
                        setBannerDialog(false)
                        getBanners()
                    }
                })
        }

    }
    const onChangePicture = async (e) => {
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
        const image = e.target.files[0];
        const formData = new FormData();
        formData.append('file', image);
        await axios.post('https://api.wingo-api.com/api/saveImage', formData, { headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer '+myUser.token } })
            .then(function (response) {
                setImage(response.data.data)
            })

    };
    const deleteBanner= async(id)=>{
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
        var service = {
            method: 'Delete',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true',
                'mode': 'no-cors',
                'Authorization': 'Bearer '+myUser.token 
            },
        }
        fetch('https://api.wingo-api.com/api/banners/' + id+ '', service).then(res => res.json())
            .then(response => {
                if (response.status === true) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Banner Deleted Succesfully', life: 3000 });
                    getBanners()
                    setDeleteDialog(false)
                }
            })
    }
    const itemTemplateData = (data) => {
        return (
            <div className="product-item">
                <div className="row">
                    <img src={data.image}className="pl-4 pb-3" style={{ width: '280px' }} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} />
                    <div className="product-action deleteBtn" >
                        <Button icon="pi pi-trash" className="p-button-danger p-mr-2 marginrightbtn" onClick={()=>deleteBanner(data.banner_id)} ></Button>
                    </div>
                </div>

            </div>
        );
    }
    const bannerDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => setBannerDialog(false)} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveBanner} />
        </React.Fragment>
    );
    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 pl-0">
                            <div className="page-title-box">
                                <h4 className="page-title">Banners</h4>
                            </div>
                        </div>
                    </div>
                    <Toast ref={toast} />
                    <div>
                        <div className="row mb-2">
                            <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2 marginrightbtn" onClick={() => setBannerDialog(true)} />
                            <Button label="Delete" icon="pi pi-trash" className="p-button-danger p-mr-2 marginrightbtn" onClick={() => setDeleteDialog(true)} />

                        </div>
                        <div className=''>

                            <Galleria value={images} activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)} responsiveOptions={responsiveOptions} numVisible={3}
                                item={itemTemplate} thumbnail={thumbnailTemplate} style={{ maxWidth: '640px' }} />
                        </div>
                    </div>
                </div>
            </div >
            <Dialog visible={bannerDialog} header="Banner Product Image" style={{ width: '450px' }} footer={bannerDialogFooter} modal className="p-fluid" onHide={() => setBannerDialog(false)}>
                <div className='p-field'>
                    <p>Select Product</p>
                    <Dropdown filter showClear options={products} value={selectedProduct} onChange={(e) => setSelectedProduct(e.value)} placeholder="Product" />
                </div>
                <div className='p-field'>
                    <label htmlFor="phoneNumber">Upload Image</label>
                    <input id="profilePic" type="file" onChange={onChangePicture} />
                </div>
                <div className="field">
                    <label htmlFor="phoneNumber">Image Link</label>
                    <InputText value={image} />
                </div>
            </Dialog>
            <Dialog visible={deleteDialog} header="Banners" style={{ width: '450px' }} modal className="p-fluid" onHide={() => setDeleteDialog(false)}>
                <div className="card">
                    <DataScroller value={images} itemTemplate={itemTemplateData} rows={5} inline scrollHeight="500px" header="Scroll Down to Load More" />
                </div>
            </Dialog>
        </div >
    )
}
function Banners() {
    return (
        <>
            <div className="App">
                <SideMenu />
                <BannersList />
            </div>
        </>
    );
}
export default Banners;