
import '../App.css';


function Home() {
  return (
      <div className="start-bg">
   

     

  </div>
  );
}

export default Home;