import React, { useState, useRef } from "react";
import { useEffect } from 'react';
import Form from "react-bootstrap/Form";
import { Button } from 'primereact/button';
import Logo from "../assets/images/logo.png";
import { useHistory } from "react-router-dom";
import AuthService from "../api-library/auth.service";
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { initializeApp } from 'firebase/app';
import { ApiUrlService } from '../api-library/api-services';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getMessaging, getToken, onMessage} from 'firebase/messaging';

export default function Login() {
  const toast = useRef(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false)


  const apiUrlService = new ApiUrlService()

   const fetchData = async () =>  {
      const myUser = JSON.stringify(await AsyncStorage.getItem('myUser'))
      //alert(isLoggedIn)
      if (myUser && myUser.isLoggedIn) {
        setIsLoggedIn(true)
      }
      else {
       setIsLoggedIn(false)
      }
     
    }




let messaging = null;

  useEffect(() => {
   
    fetchData();

   





  }, []);
  
  function validateForm() {
    return email.length > 0 && password.length > 0;
  }
  const handleSubmit = async e => {
    e.preventDefault();
    setShowOverlay(true)
    AuthService.login(email, password).then(
      (res) => {
        setShowOverlay(false)
        if (res.Status == "false") {
          toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Incorrect Username or Password', life: 3000 })
        } else {
          if(res.role === 'Role_ADMIN'){
          AsyncStorage.setItem("myUser",
          JSON.stringify({
            token: res.access_token,
            role:res.role,
            isLoggedIn:true,
            email:email
          }))
          history.push("/pendingOrders");
          window.location.reload()
         document.body.style.backgroundImage = `url('')`;
          document.body.style.backgroundColor = "#FFFFFF";
         
          }
          else{
             toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Incorrect Username or Password', life: 3000 })
          }
          
        }
      }
    )
  }
  document.body.style.backgroundColor = "#008242";
  //document.body.style.backgroundImage = `url(${Background})`;
  document.body.style.backgroundRepeat = "no-repeat";
  document.body.style.backgroundPosition = "inherit";
  document.body.style.backgroundSize = "cover";
  return (
    <div className="Login">
      <Toast ref={toast} />
      <div className="overlay" >
        {
          showOverlay ? <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" animationDuration=".5s" />
            : null
        }
      </div>
      <img src={Logo} style={{width:200, height:200}} alt="Logo" className="logo" />
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="email">
          <Form.Control
            autoFocus
            type="email"
            value={email}
            placeholder="Place your email..."
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="password">
          <Form.Control
            type="password"
            value={password}
            placeholder="Place your password..."
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Button size="lg" type="submit" disabled={!validateForm()} className="webBtn">
          Login
        </Button>
      </Form>
    </div>
  );
}