import React, { useState, useEffect, useRef } from 'react';
import SideMenu from './side-menu';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { ApiUrlService } from '../api-library/api-services';
import 'primereact/resources/themes/saga-blue/theme.css';
import AsyncStorage from '@react-native-async-storage/async-storage';

import 'primereact/resources/primereact.min.css';
const CustomersTable = () => {

    const [globalFilter, setGlobalFilter] = useState(null);
const[customers,setCustomers]=useState([])
    const toast = useRef(null);
    const dt = useRef(null);
    const apiUrlService = new ApiUrlService()
    const [loading, setLoading] = useState(false);
    let emptyCustomer = {
        customer_id: '',
        fullName:''
    }
    const [customer, setCustomer] = useState(emptyCustomer);

    useEffect(() => {
        getCustomers()
    }, []);

    const getCustomers = async() => {
        setCustomers([])
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
        var service = {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + myUser.token
            }
        }
        fetch('https://api.wingo-api.com/api/users', service).then(res => res.json())
            .then(response => {
                setCustomers(response.data)
                setLoading(false)
            });
    }
   
   
  
    const exportCSV = () => {
        dt.current.exportCSV();
    }
   
 
   


    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }
 


    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const hideDeleteDialog = () => {
        setCustomer(emptyCustomer)
        setDeleteCustomerDialog(false)
    }
    const deleteCustomer = () => {
        setLoading(true)
            var service = apiUrlService.DeleteApi();
            fetch('https://api.wingo-api.com/api/user/' + customer.customer_id + '', service).then(res => res.json())
                .then(response => {
                    setLoading(false)
                    if (response.status === true) {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Customer Deleted Succesfully', life: 3000 });
                        getCustomers()
                        setDeleteCustomerDialog(false);
                        setCustomer(emptyCustomer);
                    }
                })
       

    }
    const deleteCustomerDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteCustomer} />
        </React.Fragment>
    );
    const [deleteCustomerDialog, setDeleteCustomerDialog] = useState(false);

    const confirmDeleteUser= (rowData) => {
        setCustomer(rowData);
        setDeleteCustomerDialog(true);
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteUser(rowData)} />
            </React.Fragment>
        );
    }
 
    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 pl-0">
                            <div className="page-title-box">
                                <h4 className="page-title">Customers</h4>
                            </div>
                        </div>
                    </div>
                    <div className="pt-3" >
                        <Toast ref={toast} />
                        <div className="datatable-responsive-demo">
                            <div className="card ">
                                <Toolbar className="p-mb-4" right={rightToolbarTemplate} left={header} ></Toolbar>
                                <DataTable ref={dt} value={customers} showGridlines
                                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} 
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" scrollable scrollHeight="300px" rows={20} loading={loading}
                                    globalFilter={globalFilter} scrollable scrollHeight="280px">
                                    <Column field="fullName" header="Name" sortable></Column>
                                    <Column field="email" header="Email" sortable></Column>
                                    <Column field="phoneNumber" header="Phonenumber" sortable></Column>
                                    <Column body={actionBodyTemplate}  style={{ width: '5rem' }}></Column>
                                </DataTable>
                            </div>
                        </div>

                    </div>
                    <Dialog visible={deleteCustomerDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCustomerDialogFooter} onHide={() => setDeleteCustomerDialog(false)}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                {customer && <span>Are you sure you want to delete <b>{customer.fullName}</b>?</span>}
                            </div>
                        </Dialog>
                </div>
            </div >
        </div >
    )
}
function Customers() {
    return (
        <>
            <div className="App">
                <SideMenu />
                <CustomersTable />
            </div>
        </>
    );
}
export default Customers;