import React, { useState, useEffect, useRef } from 'react';
import SideMenu from './side-menu';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { ApiUrlService } from '../api-library/api-services';
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css';
import AsyncStorage from '@react-native-async-storage/async-storage';

const SoftDrinksList = () => {

    let emptyProduct = {
        softDrink_id: '',
        name: '',
    };
    const [softDrinks, setSoftDrinks] = useState([]);
    const apiUrlService = new ApiUrlService()
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);

    const toast = useRef(null);
    const dt = useRef(null);

    const [submitted, setSubmitted] = useState(false);
    const getSoftDrinks = () => {
        setLoading(true)
        var service = apiUrlService.GetApi();
        fetch('https://api.wingo-api.com/api/softDrink', service).then(res => res.json())
            .then(data =>
                setSoftDrinks(data.data))
        setLoading(false);

    }
    useEffect(() => {
        getSoftDrinks()

    }, []);
    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );
    const exportCSV = () => {
        dt.current.exportCSV();
    }
    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    }
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    }
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }
    const saveProduct = async() => {
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
        setSubmitted(true);
        if (product.name.trim()) {
            const body = {

                "name": product.name
            }
            var service = apiUrlService.PostApi(body,myUser.token)
            fetch('https://api.wingo-api.com/api/softDrink', service).then((response) => response.json())
                .then((response) => {
                    if (response.status === true) {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Added Successfully', life: 3000 });
                        setSubmitted(false)
                        getSoftDrinks()
                        setProductDialog(false);
                        setProduct(emptyProduct);
                    }
                })
           

        }
    }
    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setDeleteDialog(false)
    }
    const deleteProduct = () => {
        setLoading(true)
            var service = apiUrlService.DeleteApi();
            fetch('https://api.wingo-api.com/api/softDrink/' + product.softDrink_id + '', service).then(res => res.json())
                .then(response => {
                    setLoading(false)
                    if (response.status === true) {
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted Succesfully', life: 3000 });
                        getSoftDrinks()
                        setDeleteDialog(false);
                    }
                })
       

    }
    const deleteDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </React.Fragment>
    );
    const [deleteDialog, setDeleteDialog] = useState(false);

    const confirmDeleteUser= (rowData) => {
        setProduct(rowData);
        setDeleteDialog(true);
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteUser(rowData)} />
            </React.Fragment>
        );
    }
    const productDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </React.Fragment>
    );
    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 pl-0">
                            <div className="page-title-box">
                                <h4 className="page-title">Soft Drinks</h4>
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                    <div className="pt-3" >
                        <Toast ref={toast} />
                        <div className="datatable-responsive-demo">
                            <div className="card ">
                                <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                                <DataTable ref={dt} value={softDrinks}
                                    dataKey="softDrink_id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                    globalFilter={globalFilter} header={header} responsiveLayout="scroll">
                                    <Column field="name" header="Name" sortable style={{ minWidth: '16rem' }}></Column>
                                    <Column body={actionBodyTemplate}  style={{ width: '5rem' }}></Column>

                                </DataTable>
                            </div>
                        </div>

                    </div>
                    <Dialog visible={productDialog} style={{ width: '450px' }} header="Product Details" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={product.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !product.name })} />
                            {submitted && !product.name && <small className="p-error">Name is required.</small>}
                        </div>



                    </Dialog>
                    <Dialog visible={deleteDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDialogFooter} onHide={() => setDeleteDialog(false)}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                {product && <span>Are you sure you want to delete <b>{product.name}</b>?</span>}
                            </div>
                        </Dialog>
                </div>
            </div >
        </div >
    )
}
function SoftDrinks() {
    return (
        <>
            <div className="App">
                <SideMenu />
                <SoftDrinksList />
            </div>
        </>
    );
}
export default SoftDrinks;