import React, { useState, useEffect, useRef } from 'react';
import SideMenu from './side-menu';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { OrderList } from 'primereact/orderlist';
import { ApiUrlService } from '../api-library/api-services';
import { RadioButton } from 'primereact/radiobutton';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css';
import { ColumnGroup } from 'primereact/columngroup';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Row } from 'primereact/row';
const OrdersTable = () => {
    let emptyOrder = {
        order_id: '',
        totalAmount: '',
        cart: [],

    };
    let emptyLocation = {
        city: '',
        apartment: '',
        street: '',
        location_id: '',
        type: '',
        google_map_coordinates: {}
    };

     let componentRef = React.useRef();
   
    const [rowDataArray, setRowDataArray] = useState('');
    const [orders, setOrders] = useState([]);
    const [orderDialog, setOrderDialog] = useState(false);
    const [selectedDriver, setSelectedDriver] = useState();
    const [order, setOrder] = useState(emptyOrder);
    const [selectedOrders, setSelectedOrders] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [imageDialog, setImageDialog] = useState(false);
    const [imageTitle, setImageTitle] = useState('');
    const [srcImage, setSrcImage] = useState('');
    const [statuses, setStatuses] = useState([])
    const [selectedStatus, setSelectedStatus] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);
    let today = new Date();
    const [divID, setDivID] = useState(1);
    const apiUrlService = new ApiUrlService()
    const [showCountDown, setCountDown] = React.useState(false);
    const [disabledButton, setDisabled] = React.useState(true);
    const [driversButtons, setDriversButton] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedPriority, setSelectedPriority] = useState(null);
    const [priorities, setPriorities] = useState([])
    const [inputList, setInputList] = useState([{ image: {}, name: "" }]);
    const [disableSave, setDisableSave] = React.useState(false);
    const [date, setDate] = useState(null);
    const [productList, setProductList] = useState([{ image: {}, name: "", price: "", quantity: "" }]);
    useEffect(() => {
        let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(today)
        setDate(new Date(date))
        getOrders(date)
    }, []);

    const getOrders = async(date) => {
        setOrders([])
        const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
        setLoading(true)
          var service = {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + myUser.token
            }
        }
        fetch('https://api.wingo-api.com/api/orders', service).then(res => res.json())
            .then(response => {
                setOrders(response.data)
                setLoading(false)
            });
    }
   
    const timerProps = {
        isPlaying: true,
        size: 40,
        strokeWidth: 6
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _order = { ...order };
        _order[`${name}`] = val;
        setOrder(_order);
    }
   
 
   
    const itemTemplate = (item) => {
        return (
            <div className="product-item">
                <div key={item.driver_Id} className="p-field-radiobutton mr-2">
                    <RadioButton inputId={item.driver_Id} value={item.driver_Id} onChange={(e) => setSelectedDriver(e.value)} checked={selectedDriver === item.driver_Id} />
                </div>
                <div className="product-list-detail">
                    <h5 className="p-mb-2">{item.first_name} {item.last_name}</h5>
                    <span className="product-category">{item.phoneNumber}</span>
                </div>
              
            </div>
        );
    }

    const editOrder = async(order) => {
         statuses.length = 0
             const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))

        setOrder({ ...order });
        setInputList([{ image: {}, name: "" }])
        setProductList([{ image: {}, name: "", price: "", quantity: "" }])
        setLoading(true)
        if (order.cart.length === 0) {
            setInputList([])
            var service = apiUrlService.GetApi();
            fetch('https://api.wingo-api.com/api/order/' + order.order_id + '', service).then(res => res.json())
                .then(response => {
                    setProductList(response.data)
                   
                })
        }
        else if (order.cart.length !== 0) {
            setProductList([])
            setInputList(order.cart)
            setLoading(false)
                setOrderDialog(true);
        }
         var service = {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + myUser.token
            }
        }
        fetch('https://api.wingo-api.com/api/orderStatus', service).then(res => res.json())
            .then(response => {
                statuses.length = 0
                for (var j = 0; j < response.data.length; j++) {
                    const data = {
                        label: response.data[j].name,
                        value: response.data[j].orderStatus_id
                    }
                    statuses.push(data)
                }
                setStatuses(statuses);
                setSelectedStatus(order.orderStatus.orderStatus_id)
            });
    
    }


 
    const exportCSV = () => {
        dt.current.exportCSV();
    }
   
 
   


    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }
    const actionImageTemplate = (rowData) => {
        return (
            <React.Fragment>
                <img src={rowData.image} className="productImage" onClick={e => openImage(rowData.name, rowData.image)} />
            </React.Fragment>
        );
    }


    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

 
    const statusBodyTemplate = (rowData) => {
        var x = rowData.orderStatus.name;
        return <span className={`product-badge status-${x}`}>{rowData.orderStatus.name}</span>;
    }
    const priorityBodyTemplate = (rowData) => {
        var x = rowData.priority_name;
        return <span className={`product-badge status-${x}`}>{rowData.priority_name}</span>;
    }
    const historyStatusBodyTemplate = (rowData) => {
        var x = rowData.statusName;
        return <span className={`product-badge status-${x}`}>{rowData.statusName}</span>;
    }
    const actionEditTemplate = (rowData) => {
        return (
            <React.Fragment>
               
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-primary p-mr-2" onClick={() => editOrder(rowData)} />
                  

            </React.Fragment>
        );
    }
    const searchClick = () => {
        var selectedDate = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(date)

        getOrders(selectedDate)
    }
    const refreshClick = () => {
        var selectedDate = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(date)

        getOrders(selectedDate)
    }
    const saveOrder = () => {
        setSubmitted(true)
        let allValid = true;
        
            
        }
    
    const hideDialog = () => {
        setOrderDialog(false)
        setSubmitted(false)
    }
   
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };
    const handleAddClick = () => {
        setInputList([...inputList, { image: {}, name: "" }]);
    };
   
    const orderDialogFooter = (
        <React.Fragment>
        </React.Fragment>
    );

    let headerGroup = <ColumnGroup>
        <Row>
            <Column header="" />
            <Column header="Order" colSpan={3} />
            <Column header="Customer" colSpan={2} />
        </Row>
        <Row>
            <Column header="" />
            <Column header="Number" />
            <Column header="Status" />
            <Column header="Date" />
            <Column header="Name" />
            <Column header="Number" />
        </Row>
    </ColumnGroup>;
    const openImage = (name, src) => {
        setImageDialog(true)
        setSrcImage(src)
        setImageTitle(name)
    }
    const hideDialogImage = () => {
        setImageDialog(false)
    }
   
    const changeOrderStatus = async(val) => {
        const body =
        {
            "orderStatus": {
                "orderStatus_id": val
            }
        }
         const myUser = JSON.parse(await AsyncStorage.getItem('myUser'))
       setLoading(true)
          var service = {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + myUser.token
            }
        }
        fetch('https://api.wingo-api.com/api/updateStatus/' + order.order_id + '', service).then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Status Updated Successfully', life: 3000 });

                    setSelectedStatus(val)
                    setLoading(false)
                    setOrderDialog(false)
                    refreshClick();
                    

                }
            })
    }
 
    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 pl-0">
                            <div className="page-title-box">
                                <h4 className="page-title">Orders</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row p-grid pb-2 pt-2">
                        <div className="col-12"></div>
                        <div className="col-12 col-sm-10 col-md-12 col-lg-12 d-flex">
                         
                            <Button label=" Refresh" icon="pi pi-refresh " className="p-button-info" onClick={refreshClick} />
                        </div>
                    </div>
                    <div className="pt-3" >
                        <Toast ref={toast} />
                        <div className="datatable-responsive-demo">
                            <div className="card ">
                                <Toolbar className="p-mb-4" right={rightToolbarTemplate} left={header} ></Toolbar>
                                <DataTable ref={dt} value={orders} showGridlines
                                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} headerColumnGroup={headerGroup}
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" scrollable scrollHeight="300px" rows={20} loading={loading}
                                    globalFilter={globalFilter} scrollable scrollHeight="280px">
                                    <Column body={actionEditTemplate} ></Column>
                                    <Column field="orderNumber" sortable></Column>
                                    <Column field="orderStatus" sortable body={statusBodyTemplate}></Column>
                                    <Column field="dateCreated" sortable></Column>
                                    <Column field="customer.fullName" sortable></Column>
                                    <Column field="customer.phoneNumber"  sortable></Column>
                                </DataTable>
                            </div>
                        </div>

                  
                        
             
                       
                        <Dialog visible={orderDialog} style={{ width: '1000px' }} header="Order Details" modal className="p-fluid" footer={orderDialogFooter} onHide={hideDialog}>
                         <div className="p-field">
                                <label htmlFor="status">Status</label>
                                <Dropdown id="status" options={statuses} value={selectedStatus} onChange={(e) => changeOrderStatus(e.value)} placeholder="Status" />
                            </div>
                        <div className="p-field">

                                <label htmlFor="phoneNumber">Total Amount</label>
                                <InputText value={order.totalAmount} onChange={(e) => onInputChange(e, 'totalAmount')} />
                            </div>
                                   {order.promoCode &&  <label htmlFor="phoneNumber">PromoCode</label>}
                                {order.promoCode ? <InputText value={order.promoCode.code}/>: <label htmlFor="phoneNumber"></label>}

                                   <div>
                                     {order.coupon && <label htmlFor="phoneNumber">Coupon</label>}
                                {order.coupon ? <InputText value={order.coupon.description}/>: <label htmlFor="phoneNumber"></label>}
                                    </div>
                                
                {inputList.length > 0 &&
                                <div className="p-field" ref={el=>(componentRef=el)}>
                                    <label htmlFor="phoneNumber">Items</label>
                                    {inputList.map((x, i) => {
                                        return (
                                                                 <div className="mr-1 mb-1 d-flex " style={{ width: '700px' }}>
                                                <InputTextarea
                                                    name="name"
                                                    placeholder="Order dscripion"
                                                    value={x && x.product && x.product.name}
                                                    onChange={e => handleInputChange(e, i)} rows={1} cols={1} />
                                                <label style={{ width: '250px', paddingLeft: 20 }}> Quantity: {x && x.quantity}</label>
                                                  {x&&x.notes? <label style={{ width: '500px',paddingLeft:30  }}>Notes: {x&&x.notes}</label>:<label></label>}
                                                     {x&& x.itemsAddOns && x.itemsAddOns.map(z =>{
                                                        return (<label></label>)
                                                    })}
                                                {x && x.product && x.product.image !== null ? x.product.image && <img style={{ width: '100px', cursor: 'pointer', paddingLeft: 40 }} src={x.product.image} onClick={e => openImage(x.name, x.product.image)} /> : <label htmlFor="phoneNumber"></label>}


                                            </div>
                                        );
                                    })}
                                </div>
                            }
                            {productList.length > 0 &&
                                <div className="p-field">
                                    <label htmlFor="phoneNumber">Products</label>
                                    <div className="card">
                                        <DataTable value={productList} responsiveLayout="scroll">
                                            <Column field="name" header="Name"></Column>
                                            <Column field="price" header="Price"></Column>
                                            <Column field="image" header="Image" body={actionImageTemplate}></Column>
                                            <Column field="quantity" header="Quantity"></Column>
                                            <Column field="notes" header="Note"></Column>
                                        </DataTable>
                                    </div>
                                </div>
                            }
                            {order.notes && <div className="p-field">
                                <label htmlFor="phoneNumber">Notes</label>
                                {order.notes.map((x) => {
                                    return (
                                        <div className="mr-1 mb-1 d-flex ">
                                            <p>- {x}</p>
                                        </div>
                                    );
                                })}
                            </div>}
                             <ReactToPrint
                   trigger={()=>{
                       return <Button className="print">Print</Button>
                   }}
                   content = {()=> componentRef}
                   documentTitle= 'new doc'
                   pageStyle="print"
                   />
                        </Dialog>
                       
                        <Dialog visible={imageDialog} style={{ width: '450px' }} header={imageTitle}  modal className="p-fluid" onHide={hideDialogImage}>
                            <div className="datatable-responsive-demo">
                                <div className="card ">
                                    <img style={{ width: '400px' }} src={srcImage} />
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div >
        </div >
    )
}
function Orders() {
    return (
        <>
            <div className="App">
                <SideMenu />
                <OrdersTable />
            </div>
        </>
    );
}
export default Orders;